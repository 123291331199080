import styled from 'styled-components'
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import { Input, Button } from 'antd'
import _ from 'lodash'

import ContentWithTitle from './ContentWithTitle'

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  * {
    height: fit-content;
  }
`

const RowContainer = styled.div`
  display: ${props => props.footerless ? 'none' : 'flex'};
  gap: 5px;
`

const FormList = ({ items, footerless, onDismiss, onSave, initData = {}, className, onChange = () => {} }, ref) => {
  const [data, setData] = useState(initData)

  const handleChangedata = (key, newData) => setData(data => { return { ...data, [key]: newData } })

  useImperativeHandle(ref, () => ({
    resetData: (_data) => _data ? setData(_data) : setData(initData),
    getData: () => data
  }))

  useEffect(() => {
    onChange(data)
  }, [data])
  if (_.isEmpty(items)) return null

  return (
    <Container className={className}>

      {items.map((item, index) => {
        const type = _.get(item, ['type'], null)
        const dataParser = type === 'input' ? (e) => e.target.value : _.get(item, ['dataParser'], (e) => e)
        const onChange = (newData) => handleChangedata(item.key, dataParser(newData))
        const renderComponent = (props) => _.get(item, ['render'], () => {})(props)
        return (
          <ContentWithTitle key={`custom-form_${index}`} title={_.get(item, ['title'])}>
            {type === 'input'
              ? <Input value={_.get(data, [item.key], null)} onChange={onChange} />
              : renderComponent({ onChange: onChange, value: _.get(data, [item.key], null) })}
          </ContentWithTitle>)
      })}

      <RowContainer footerless={footerless}>
        <Button onClick={onDismiss}>취소</Button>
        <Button onClick={() => onSave(data)}>저장</Button>
      </RowContainer>

    </Container>
  )
}

export default forwardRef(FormList)
