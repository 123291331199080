import { Select } from 'antd'
import React from 'react'
import _ from 'lodash'

const { Option } = Select

const DropDownSelect = ({ items, value, defaultValue, className, onSelect, bordered = true }) => {
  return (
    <Select
      value={value}
      // defaultValue={defaultValue}
      className={className}
      onSelect={onSelect}
      bordered={bordered}
    >
      {items.map((val) => {
        if (_.get(val, ['label'], false) && _.get(val, ['value'], false)) {
          return (
            <Option
              key={val.value}
              value={val.value}
            >
              {val.label}
            </Option>
          )
        } else return (<Option value={val} key={val}>{val}</Option>)
      })} 
    </Select>
  )
}

export default DropDownSelect
