import { gql } from '@apollo/client'

export const addUser = gql `
  mutation AddUser {
    addUser {
      ok
      error
      _debug
    }
  }
`;
export const addCase = gql `
  mutation AddCase($data: CaseInput) {
    addCase(data: $data) {
      ok
      error
      _debug
    }
  }
`;
export const addUserCase = gql `
  mutation AddUserCase($data: UserCaseInput!, $userAgreement: String) {
    addUserCase(data: $data, userAgreement: $userAgreement) {
      data {
        caseId
        status
      }
      message
      # caseNumber
      # court
      # partyNames
      # info
      # events {
      #   eventId
      #   date
      #   type
      #   text
      #   updated
      # }
      # lastEventType
      # lastEventDate
      # nextEventDate
      # lastUpdated
    }
  }
`;
export const setUserMemo = gql `
  mutation SetUserMemo($caseId: Int, $memo: String, $managementName: String) {
    setUserMemo(caseId: $caseId, memo: $memo, managementName: $managementName) {
      ok
      error
      _debug
    }
  }
`;
export const deleteUserCase = gql `
  mutation DeleteUserCase($caseId: Int) {
    deleteUserCase(caseId: $caseId) {
      ok
      error
      _debug
    }
  }
`;
export const setCaseNotification = gql `
  mutation SetCaseNotification($id: ID, $shouldNotify: Boolean) {
    setCaseNotification(id: $id, shouldNotify: $shouldNotify) {
      ok
      error
      _debug
    }
  }
`;
export const setNotificationPeriod = gql `
  mutation SetNotificationPeriod($period: Int) {
    setNotificationPeriod(period: $period) {
      ok
      error
      _debug
    }
  }
`;
export const setSystemUpdatePeriod = gql `
  mutation SetSystemUpdatePeriod($period: Int) {
    setSystemUpdatePeriod(period: $period) {
      ok
      error
      _debug
    }
  }
`;
export const updateSystem = gql `
  mutation UpdateSystem {
    updateSystem {
      ok
      error
      _debug
    }
  }
`;
export const deleteUserCases = gql`
  mutation DeleteUserCases {
    deleteUserCases {
      ok
      error
      _debug
    }
  }
`
export const withdrawFromService = gql`
  mutation WithdrawFromService {
    withdrawFromService {
      ok
      error
      _debug
    }
  }
`
export const addErrorReport = gql`
  mutation AddErrorReport ($title: String, $content: String) {
    addErrorReport (
      title: $title,
      content: $content
    ) {
      ok
      error
      _debug
    }
  }
`

export const setUserCaseStatus = gql`
  mutation setUserCaseStatus ($caseId: Int, $status: CaseStatus) {
    setUserCaseStatus (caseId: $caseId, status: $status) {
      ok error
    }
  }
`

// export const checkUserAgreement = gql`
//   mutation checkUserAgreement ($version: String) {
//     checkUserAgreement (version: $version) {
//       ok error
//     }
//   }
// `