import { Typography } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  padding: 0px 10px;
`

const BannerHeader = ({ onCancel, onCancelToday }) => {
  return (
    <Container>
      <Typography.Link onClick={onCancelToday}>오늘 그만보기</Typography.Link>
      <Typography.Link onClick={onCancel}>닫기</Typography.Link>
    </Container>
  )
}

export default BannerHeader
