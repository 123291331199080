import './App.css'
import 'antd/dist/reset.css'
import { Typography } from 'antd'
import { isMobile } from 'react-device-detect'

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'
import styled from 'styled-components'

import RootContainer from './containers/RootContainer'
import CasesContainer from './containers/CasesContainer'
import CaseDetailContainer from './containers/CaseDetailContainer'
import { getScrollbarWidth } from './config'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  overscroll-behavior: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  html {
    -webkit-locale: "ko";
  }
  body {
    background-color: #ffffff;
  }
`
const LawNotificationContainer = styled.div`
  padding: 10px;
  background-color: #333333;
`
const BottomBorderContainer = styled.div`
  max-width: 1500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const FlexStub = styled.div`
  flex: 1;
`

function App () {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <RootContainer />,
      // errorElement: <ErrorFallBack />,
      children: [
        {
          path: '/',
          element: <CasesContainer />,
        },
        {
          path: 'number/:searchId',
          element: <CasesContainer />,
        },
        {
          path: 'date/:datestring',
          element: <CasesContainer />,
        },
        {
          path: '/detail/:caseId',
          element: <CaseDetailContainer />
        }
      ]
    }
  ])

  return (
    <Container id='scrollContainer' isMobile={isMobile} scrollBarWidth={getScrollbarWidth()}>

      <RouterProvider router={router} />

      <FlexStub />

      <LawNotificationContainer>
        <BottomBorderContainer>
          <Typography.Text style={{ cursor: 'pointer', color: '#fff' }}>개인정보처리방침</Typography.Text>
        </BottomBorderContainer>
      </LawNotificationContainer>

    </Container>
  )
}

export default App
