import _ from 'lodash'
import styled from 'styled-components'
import { useRef, useState } from 'react'
import { Avatar, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import SetUserForm from './form'
import { NavBack } from '../../common'
import SetUserHeader from './SetUserHeader'
import ResponsiveModal from '../ResponsiveModal'
import RedDot from '../../common/RedDot'
import BaseForm from './form/BaseForm'

const RowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
`

const SetUserResponsive = ({ windowSize, onOpenChange, isButtonHidden, userName, isUnreadByTitle, userQueryRefetch }) => {
  // states
  const modalRef = useRef()
  const [path, setPath] = useState([])
  const [id, setId] = useState(null)
  const title = _.last(path)

  // handlers
  const handleClickRow = title => {
    if (title === '로그아웃') {
      window.sessionStorage.clear()
      window.location.replace('https://www.seoulbar.or.kr')
      return
    }
    setPath([title])
  }
  const handleClickSubRow = (title, _id) => {
    setId(parseInt(_id))
    setPath(path => [...path, title])
  }
  const handleNavBack = () => {
    setId(null)
    setPath(path => {
      if (path.length === 2) {
        return path.slice(0, 1)
      } else {
        return []
      }
    })
  }
  const handleCancel = () => {
    setPath([])
    modalRef?.current?.close()
  }

  // variables
  const modalTriggerButtonSize = windowSize === 'large' ? '30px' : '20px'

  // renderers
  const renderButton = ({ onClick, ...props }) => {
    return (
      <div style={{ display: isButtonHidden ? 'none' : 'flex', flexDirection: 'row' }}>
        <UserOutlined
          {...props}
          onClick={onClick}
          style={{
            fontSize: modalTriggerButtonSize
          }}
          color='black'
        />
        {(isUnreadByTitle['공지사항'] || isUnreadByTitle['FAQ']) && <RedDot />}
      </div>
    )
  }
  const ModalTitle = () => _.isEmpty(path)
    ? (
      <RowContainer>
        <Avatar />
        <Typography.Text>{userName}</Typography.Text>
      </RowContainer>
    )
    : <NavBack title={title} onNavBack={handleNavBack} />

  return (
    <ResponsiveModal
      ref={modalRef}
      title={<ModalTitle />}
      renderButton={renderButton}
      onCancel={() => setPath([])}
      onOpenChange={onOpenChange}
      renderHeader={() => (
        <SetUserHeader
          title={title}
          level={5}
          onNavBack={_.isEmpty(path) ? handleCancel : handleNavBack}
        />
      )}
    >
      {_.isEmpty(path)
        ? (
          <BaseForm
            isUnreadByTitle={isUnreadByTitle}
            onClickRow={handleClickRow}
          />
        )
        : (
        <SetUserForm
          id={id}
          path={path}
          userQueryRefetch={userQueryRefetch}
          onClickRow={handleClickSubRow}
        />)}
    </ResponsiveModal>
  )
}

export default SetUserResponsive
