import _ from 'lodash'
import { useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/client'

import { agreementVersion } from '../config'
import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import { useWindowSize } from '../hook/useWindowSize'
import { getScrollbarWidth } from '../config'
import Header from '../components/Responsive/Header'
import { NavBack } from '../components/common'
import BannerResponsive from '../components/Responsive/BannerResponsive'
import CalendarResponsive from '../components/Responsive/CalendarResponsive'

const Container = styled.div`
  display: ${props => props.hidden ? 'none' : 'flex'};
  flex-direction: column;
  align-items: center;
`
const OutletContainer = styled.div`
  display: flex;
  max-width: 1400px;
  width: 100%;
  overflow: hidden;
`
const CalendarContainer = styled.div`
  display: ${props => props.hidden ? 'none' : 'flex'};
  max-width: 1500px;
  width: 100%;
  justify-content: center;
`
const Notice = styled.div`
  padding: 0.3em 1em;
  background-color: #282c34;
  font-size: 0.8em;
  color: #fff;
  z-index: 2;
`
const HeaderContainer = styled.header.attrs(props => {
  return {
    style: {
      borderBottom: props.isMobile ? null : '1px solid #dedede',
      width: `calc(100% - ${props.scrollbarWidth}px)`,
      display: props.hidden ? 'none' : 'flex'
    }
  }
})`
  flex: 1;
  max-width: 1400px;
  display: flex;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 2;
  flex-direction: column;
  .navBack {
    display: flex;
    height: 51px;
    align-items: center;
    padding: 10px;
  }
`

const HeaderContainerStub = styled.div`
  width: 100%;
  height: ${props => props.height};
`

const RootContainer = () => {
  const { pathname } = useLocation()
  const { datestring } = useParams()
  const navigate = useNavigate()
  const { windowSize } = useWindowSize()

  // states
  const [isResponsiveOpened, setIsResponsiveOpened] = useState(null)
  const [shouldHideHeaderOnBannerPopUp, setShouldHideHeaderOnBannerPopUp] = useState(null)
  const [viewMode, setViewMode] = useState('progress') // viewmode === progress then 진행중인 사건
  
  // apollo quries & mutations
  const { data: userData, refetch: userQueryRefetch } = useQuery(queries.user)
  const [addErrorReport] = useMutation(mutations.addErrorReport, {
    onCompleted: (data) => {
      const { ok, error } = _.pick(_.get(data, ['addErrorReport']), ['ok', 'error'])
      console.log(ok, error)
    }
  })

  // handlers
  const onChangeCalendarDate = val => navigate(`/date/${val}`)
  const handleBannerOpenChange = (_opened) => {
    setIsResponsiveOpened(_opened)
    setShouldHideHeaderOnBannerPopUp(_opened) 
  }

  // variables
  const isMobile = windowSize === 'small'
  const isSearchedByExactCaseNumber = pathname.includes('detail')
  const isMobileFullScreenForm = isMobile && isResponsiveOpened

  // header props
  const userName = _.get(userData, ['user', 'name'], '홍길동')
  const scrollbarWidth = getScrollbarWidth()
  const didUserAgree = _.get(userData, ['user', 'userAgreement']) === agreementVersion
  const responsiveHeaderHeight = windowSize === 'small'
    ? isSearchedByExactCaseNumber || pathname.includes('date')
      ? '126px'
      : '80px'
    : pathname === '/' ? '75px' : '130px'
  const navBackTitle = pathname === '/' ? null : '전체 일정 보기'
  const showHeader = !(isMobile && isSearchedByExactCaseNumber)
  const headerContainerHidden = shouldHideHeaderOnBannerPopUp && isMobile
  const showNavBack = pathname !== '/' && !isResponsiveOpened
  const selectedDate = datestring ? new Date(datestring) : null

  // display red dot by comparing last accessed and updated datetime
  const { lastNoticeAccessed, lastFaqAccessed, lastNoticeUpdated, lastFaqUpdated } = _.pick(_.get(userData, ['user'], {}), ['lastNoticeAccessed', 'lastFaqAccessed', 'lastNoticeUpdated', 'lastFaqUpdated'])
  const isNoticeUnread = new Date(lastNoticeAccessed) < new Date(lastNoticeUpdated)
  const isFaqUnread = new Date(lastFaqAccessed) < new Date(lastFaqUpdated)

  const isUnreadByTitle = { '공지사항': isNoticeUnread, 'FAQ': isFaqUnread }

  return (
    <Container>
      <HeaderContainer
        isMobile={isMobile}
        scrollbarWidth={scrollbarWidth}
        hidden={headerContainerHidden}
      >
        <Notice>베타 서비스 중입니다. 일부 기능은 불안정할 수 있습니다.</Notice>
        {showHeader && (
          <Header
            windowSize={windowSize}
            userName={userName}
            didUserAgree={didUserAgree}
            isUnreadByTitle={isUnreadByTitle}
            userQueryRefetch={userQueryRefetch}
            onOpenChange={(e) => setIsResponsiveOpened(e)}
            onAddErrorReport={(d) => addErrorReport({ variables: d })}
          />
        )}
        {showNavBack && (
          <NavBack
            className='navBack'
            title={navBackTitle}
            onNavBack={() => navigate('/')}
          />
        )}
      </HeaderContainer>

      {headerContainerHidden ? null : <HeaderContainerStub height={responsiveHeaderHeight} />}

      <CalendarContainer hidden={isMobileFullScreenForm}>
        {!isSearchedByExactCaseNumber && (
          <CalendarResponsive
            windowSize={windowSize}
            selectedDate={selectedDate}
            onChangeCalendarDate={onChangeCalendarDate}
          />
        )}
      </CalendarContainer>
      
      <OutletContainer id='outlet' hidden={isMobileFullScreenForm}>
        <Outlet context={[viewMode, setViewMode]} />
      </OutletContainer>

      <BannerResponsive onOpenChange={handleBannerOpenChange} />
    </Container>
  )
}

export default RootContainer
