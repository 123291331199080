import { useLayoutEffect, useState } from "react"
import _ from 'lodash'

const getScrollTops = (scrollViewId, elemId1, elemId2) => {
  const scrollViewElement = document.getElementById(scrollViewId)
  const content = document.documentElement
  const para1 = document.getElementById(elemId1)
  const para2 = document.getElementById(elemId2)

  const para1Height = para1?.offsetHeight
  const para2Height = para2?.offsetHeight

  const maxScrollTop = scrollViewElement.scrollHeight - content.offsetHeight

  const scrollTop1 = 0
  const scrollTop2 = para1Height + scrollTop1
  const scrollTop3 = para2Height + scrollTop2

  const handleScrollTopOverflow = scrollTop => {
    return maxScrollTop > scrollTop ? scrollTop : maxScrollTop
  }

  return {
    scrollTop1,
    scrollTop2: handleScrollTopOverflow(scrollTop2) - 1, // force order
    scrollTop3: handleScrollTopOverflow(scrollTop3),
  }
}

export const useScroll = (scrollViewId, elemId1, elemId2, elemId3) => {
  const [scrollPosition, setScrollPosition] = useState(1)
  const scrollEventListener = (e) => {
    const { scrollTop2, scrollTop3 } = getScrollTops(scrollViewId, elemId1, elemId2)
    const currentScrollTop = e.target.scrollTop
    if (currentScrollTop < scrollTop2) {
      setScrollPosition(elemId1)
    } else if (currentScrollTop >= scrollTop2 && currentScrollTop < scrollTop3) {
      setScrollPosition(elemId2)
    } else setScrollPosition(elemId3)
  }
  useLayoutEffect(() => {
    const scrollViewElement = document.getElementById(scrollViewId)
    scrollViewElement.addEventListener('scroll', scrollEventListener)
    return (() => {
      scrollViewElement.removeEventListener('scroll', scrollEventListener)
    })
  }, [])
  const onChangeScrollSetPos = (pagination) => {
    const scrollViewElement = document.getElementById(scrollViewId)
    const { scrollTop1, scrollTop2, scrollTop3 } = getScrollTops(scrollViewId, elemId1, elemId2)
    if (pagination === elemId1) {
      scrollViewElement.scrollTop = scrollTop1
    } else if (pagination === elemId2) {
      scrollViewElement.scrollTop = scrollTop2
    } else if (pagination === elemId3) {
      scrollViewElement.scrollTop = scrollTop3
    }
    setScrollPosition(pagination)
  }
  return {
    scrollPosition,
    onChangeScrollSetPos
  }
}