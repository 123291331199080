import _ from 'lodash'
import { message } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import Responsive from '../components/Responsive'

import { useScroll } from '../hook/useScroll'
import { useState } from 'react'

const CaseDetailContainer = () => {
  const { caseId: _caseId } = useParams()
  const caseId = parseInt(_caseId)

  const idsForScrollPagination = ['scrollContainer', 's1', 's2', 's3', 's4']
  const { scrollPosition, onChangeScrollSetPos } = useScroll(...idsForScrollPagination)

  // states
  // event table states
  const [sortKey, setSortKey] = useState(null)
  const [sortOrder, setSortOrder] = useState(null)
  const [filter, setFilter] = useState('전체')

  // quries & mutations
  const { data, refetch } = useQuery(queries.userCase, {
    variables: { caseId },
    fetchPolicy: 'network-only'
  })
  const [setUserMemo] = useMutation(mutations.setUserMemo)
  const [deleteUserCase] = useMutation(mutations.deleteUserCase, {
    onCompleted: () => {
      message.success('삭제가 완료되었습니다.')
      window.location = '/'
      reset()
    }
  })
  const [setCaseNotification] = useMutation(mutations.setCaseNotification)
  const [setUserCaseStatus] = useMutation(mutations.setUserCaseStatus, {
    onCompleted: () => {
      reset()
    }
  })
  const reset = () => {
    sessionStorage.setItem('prevData', JSON.stringify(null))
    sessionStorage.setItem('scrollTop', 0)
  }
  // methods
  const onUserMemoSettingFinish = async (memoData) => {
    try {
      const { data } = await setUserMemo({
        variables: {
          ...memoData,
          caseId
        }, onCompleted: () => {
          refetch()
          reset()
        }
      })
      console.log(data)
    } catch (e) {
      console.log('setUserMemo ERR: ', e)
    }
  }
  const onDeleteUserCase = async () => {
    try {
      const { data } = await deleteUserCase({
        variables: {
          caseId
        }
      })
      console.log(data)
    } catch (e) {
      console.log('deleteUserCase ERR: ', e)
    }
  }
  const handleSettingCaseNotification = async (shouldNotify) => {
    try {
      const { data } = await setCaseNotification({
        variables: {
          id: caseId, shouldNotify
        }
      })
      console.log(data)
    } catch (e) {
      console.log('setCaseNotification ERR: ', e)
    }
  }
  const handleEventSorting = (dir, _sortKey) => {
    setSortKey(_sortKey)
    setSortOrder(dir)
  }
  const handleEventFilteringByType = (_type) => setFilter(_type)

  // variables
  const { memo, managementName, info, events, status } = _.get(data, ['userCase'], {})
  let infoUpdated = []
  if (_.size(info) > 0) {
    infoUpdated = _.compact([
      info[0],
      {key: '법원', value: _.get(data, ['userCase', 'court'], '')},
      !_.includes(_.map(info, 'key'), '사건명') ? {key: '사건명', value: _.get(data, ['userCase', 'name'], '')} : undefined,
      ..._.slice(info, 1)
    ])
  }

  // sort and filter events
  const _events = _.map(events, (data, index) => ({ ...data, key: index }))
  const eventsByType = _.groupBy(_events, 'type')
  const filteredEvents = filter === '전체' ? _events : _.get(eventsByType, [filter], [])
  const filteredEventsByDate = _.groupBy(filteredEvents, 'date')
  const uniqDates = _.keys(filteredEventsByDate)
  const sortedEvents = sortOrder === 'up' && sortKey === 'date'
    ? _.flatMap(_.sortBy(uniqDates, d => d), uniqDate => { return _.get(filteredEventsByDate, [uniqDate], [])  })
    : filteredEvents
  const getRecentData = (type) => _.get(eventsByType, [type], []).slice(0, 3)

  const extraInfoTables = [
    {
      dataSource: _.get(data, ['userCase', 'appeals']),
      columns: [
        {
          title: '법원',
          dataIndex: 'court',
          ellipsis: true
        },
        {
          title: '사건번호',
          dataIndex: 'case_number'
        }
      ],
      key: '심급내용'
    },
    {
      dataSource: getRecentData('기일'),
      columns: [
        {
          title: '날짜',
          dataIndex: 'date',
          ellipsis: true
        },
        {
          title: '내용',
          dataIndex: 'content'
        },
        {
          title: '결과',
          dataIndex: 'result'
        }
      ],
      key: '최근 기일내용'
    },
    {
      dataSource: getRecentData('제출서류'),
      columns: [
        {
          title: '날짜',
          dataIndex: 'date',
          ellipsis: true
        },
        {
          title: '내용',
          dataIndex: 'content'
        }
      ],
      key: '최근 제출서류 접수내용'
    }
  ] // 관련사건내용, 당사자내용, 대리인내용

  const eventTableProps = {
    sortKey: sortKey,
    sortOrder: sortOrder,
    filter,
    onSort: handleEventSorting,
    onFilter: handleEventFilteringByType
  }

  return (
    // <Container id='caseDetailContainer'>

      <Responsive
        id='caseDetail'
        {...eventTableProps}
        initManagementData={{ memo, managementName }}
        status={status}
        events={sortedEvents}
        infoUpdated={infoUpdated}
        extraInfoTables={extraInfoTables}
        scrollPositionOnMobile={scrollPosition}
        idsForScrollPagination={idsForScrollPagination}
        onDeleteUserCase={onDeleteUserCase}
        onEditFinish={onUserMemoSettingFinish}
        onChangeScrollSetPosOnMobile={onChangeScrollSetPos}
        onSettingCaseNotification={handleSettingCaseNotification}
        onSetUserCase={(status) => setUserCaseStatus({ variables: { caseId, status } })}
      />

    // </Container>
  )
}

export default CaseDetailContainer
