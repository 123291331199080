import { FileOutlined, BellOutlined, QuestionOutlined, DeleteOutlined, LogoutOutlined } from '@ant-design/icons'
import { Button, Switch, Typography } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import DeleteButton from '../../../../common/DeleteButton'
import RedDot from '../../../../common/RedDot'
const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 5px;
`

const NotificationAddOnAfterContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`
const UnreadRedDotProvider = ({ isUnread, children }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
      {children}
      {isUnread && <RedDot />}
    </div>
  )
}
const LinkToSubForm = (props) => {
  const {
    title,
    action,
    isUnread,
    type,
    buttonType,
    enabled = true,
    onClick = () => {},
    onWithdrawFromService,
    onDeleteAllCases
  } = props
  const iconByTitle = {
    '공지사항': props => <FileOutlined {...props} />,
    'FAQ': props => <QuestionOutlined {...props} />,
    '사건 전체 삭제': props => <DeleteOutlined {...props} />,
    '사건 관리 서비스 탈퇴 하기': props => <LogoutOutlined {...props} />
  }
  const Icon = ({ isUnread, ...props }) => {
    if (!_.get(iconByTitle, [title], null)) return null
    return <UnreadRedDotProvider isUnread={isUnread}>{_.get(iconByTitle, [title], () => {})(props)}</UnreadRedDotProvider>
  }

  if (type === 'button') {
    if (action === 'deleteAllCases') {
      return (
        <DeleteButton
          icon={<Icon title={title} isUnread={isUnread} />}
          className='info-button'
          onDelete={onDeleteAllCases}
          deleteText={title}
        />
      )
    } else if (action === 'stopUsingService') {
      return (
        <DeleteButton
          icon={<Icon title={title} isUnread={isUnread} />}
          className='info-button'
          modalText='정말 탈퇴하시겠습니까?'
          footerText='탈퇴하기'
          onDelete={onWithdrawFromService}
          deleteText={title}
        />
      )
    }
  }
  return (
    <Button
      className='info-button'
      disabled={!enabled}
      danger={buttonType === 'danger'}
      icon={<Icon title={title} isUnread={isUnread} />}
      onClick={onClick}
    >
      {title}
      {type === 'notification' && (
        <NotificationAddOnAfterContainer>
          <Typography.Text style={{ fontSize: 'x-small' }}>사건 알림을 받습니다.</Typography.Text>
          <Switch disabled />
        </NotificationAddOnAfterContainer>
      )}
    </Button>
  )
}

export default LinkToSubForm
