import { Typography } from 'antd'

import styled from 'styled-components'


const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
`
const Input = styled.div`
  display: flex;
  width: ${props => props.width ? `${props.width}px` : 'auto'};
  justify-content: flex-start;
`

const CustomForm = (props) => {
  const {
    flex,
    title,
    children,
    gap = 10,
    alignItems,
    textStyle = {},
    style,
    inputStyle
  } = props
  const defTextStyle = {
    display: 'flex',
    alignSelf: 'center'
  }

  return (
    <Container style={{ gap, flex, alignItems, ...style }}>
      <Typography.Text style={{ ...defTextStyle, ...textStyle, flex: 1 }}>
          {title}:
      </Typography.Text>

      <Input style={{...inputStyle, flex: 3}}>
        {children}
      </Input>
    </Container>
  )
}

export default CustomForm
