import { Button } from 'antd'
import styled from 'styled-components'

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
`

const AddCaseFooter = ({ isUserAgreeing, onCancel, onSave, loading, disabled }) => {
  if (isUserAgreeing && !loading) {
    return (
      <RowContainer>
        <Button
          type='warning'
          size='large'
          onClick={onCancel}
        >
          취소하기
        </Button>
        <Button
          id='save-button'
          type='primary'
          size='large'
          style={{ width: '100%' }}
          onClick={onSave}
        >
          동의하고 등록하기
        </Button>
      </RowContainer>
    )
  }
  return (
    <RowContainer>
      <Button
        id='save-button'
        type='primary'
        style={{ width: '90%', margin: '0 auto' }}
        size='large'
        onClick={onSave}
        loading={loading}
        disabled={disabled}
      >
        등록하기
      </Button>
    </RowContainer>
  )
}

export default AddCaseFooter
