const eventsTableColumns = [
  {
    title: '종류',
    dataIndex: 'type',
    className: 'nowrap',
    fixed: 'left',
    width: 90
  },
  {
    title: '날짜',
    dataIndex: 'date',
    width: 100,
    ellipsis: true
  },
  {
    title: '정보',
    dataIndex: 'content',
    className: 'multiline'
  },
  {
    title: '결과',
    fixed: 'right',
    width: 90,
    dataIndex: 'result'
  }
]

const infoTableColumns = [
  {
    title: '정보명',
    dataIndex: 'key'
  },
  {
    title: '',
    dataIndex: 'value'
  }
]

export {
  eventsTableColumns,
  infoTableColumns
}