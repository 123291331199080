import { message } from 'antd';
import axios from 'axios';
import React, { useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import '../../Quill.css'

const Container = styled.div`
`

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
function QuillEditor ({ onChange = () => {}, value, style = {}, onBlur = () => {}, autofocus = false, imageDisabled, ...props }) {
  const quillRef = useRef(null)
  const [showToolbar, setShowToolbar] = useState(false)

  const imageHandler = () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.addEventListener('change', async () => {
      try {
        if (!quillRef) throw new Error()
        if (!quillRef.current) throw new Error()
        const editor = quillRef.current.getEditor()
        const img = document.createElement('img')
        editor.root.appendChild(img)

        // post to s3
        const file = input.files[0]
        const filename = `${uuidv4()}`
        const data = {
          filename,
          contenttype: file.type
        }

        const s3Axios = axios.create({})
        s3Axios.defaults.headers.put['Content-Type'] = file.type

        const res = await s3Axios.post(
          `https://dya7nuu48j.execute-api.ap-northeast-2.amazonaws.com/casemgnt_s3_presigned_url`,
          data,
          { headers: { "Content-Type": "application/json"  } })

        if (res) {
          const _file = new File([file], filename, { type: file.type })
          const result = await s3Axios.put(res.data, _file, { headers: `Content-Type: ${file.type}` }).catch(e => console.log(e))
          const newUrl = `https://casemgnt-s3-banners.s3.ap-northeast-2.amazonaws.com/${filename}`
          if (result) {
            img.src = newUrl
          }
        }
      } catch (error) {
        // error handling
        message.error('이미지 업로드에 실패하였습니다.')
      }
    })
  }
  const handleBlur = () => {
    setShowToolbar(false)
    onBlur()
  }
  const defFormats = ['bold', 'italic', 'underline', 'strike', 'blockquote']
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          imageDisabled ? defFormats : [...defFormats, 'image']
        ],
        handlers: {
          image: imageHandler
        }
      }
    }
  }, [imageDisabled])
  const formats = imageDisabled ? ['header', ...defFormats] : [
    'header',
    ...defFormats,
    'image'
  ]
  return (
    <Container
      showToolbar={showToolbar}
      onClick={() => {
        setShowToolbar(true)
        quillRef?.current?.focus()
      }}
    >
      <ReactQuill
        ref={quillRef}
        {...props}
        {...value !== undefined ? { value } : {}}
        style={style}
        modules={modules}
        formats={formats}
        onChange={onChange}
        onBlur={handleBlur}
        onFocus={() => setShowToolbar(true)}
      />
    </Container>
  )
}

export default QuillEditor
