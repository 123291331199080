import { Typography } from 'antd'
import { useLocation, useParams } from 'react-router-dom'

import { parseDate } from '../config'

function RouteName () {
  const { pathname } = useLocation()
  const { searchId, datestring } = useParams()

  // variables
  const isSearchedByDate = pathname.includes('date')
  const isSearchedByCaseNumber = pathname.includes('number')
  const isSearchedByExactCaseNumber = pathname.includes('detail')
  const resultTitle = isSearchedByExactCaseNumber
    ? null
    : isSearchedByDate
      ? parseDate(datestring) + ' 일정'
      : isSearchedByCaseNumber
        ? searchId + ' 검색결과'
        : '전체일정'
  if (resultTitle) {
    return (
      <Typography.Text>{`[${resultTitle}]`}</Typography.Text>
    )
  } else return null
}

export default RouteName
