// import { useState } from "react"
import _ from 'lodash'
// import { Spin } from "antd"
const HTMLContent = ({ data, id, className }) => {
  // const [loading, setLoading] = useState(true)
  // const handleLoad = (e) => {
  //   setLoading(false)
  // }
  // const _elem = document.getElementById(id)
  // useEffect(() => {
    
  //   const elem = document.getElementById(id)
  //   elem && elem.firstChild.addEventListener('load', handleLoad)
  //   return () => {
  //     elem && elem.firstChild.removeEventListener('load', handleLoad)
  //   }
  // }, [_elem])
  return (
    <div id={id} className={className} dangerouslySetInnerHTML={ {__html: data} }></div>
  )
}

export default HTMLContent
