import { useRef } from 'react'
import { Button } from 'antd'

import DeleteButton from "../common/DeleteButton"
import { QuillEditor, FormList } from "../common"

const ErrorReport = ({ onAddErrorReport }) => {
  const formRef = useRef()

  return (
    <DeleteButton
      footerText='보내기'
      modalText={(
        <FormList
          ref={formRef}
          items={[
            {
              key: 'content',
              title: '오류 신고하기',
              render: (props) => (
                <div style={{ width: '100%' }}>
                  <QuillEditor
                    style={{ width: '100%', innerHeight: 400 }}
                    placeholder='오류 사항을 구체적으로 설명해주세요.'
                    {...props}
                  />
                </div>
          ) }]}
          footerless
        />)}
      render={(props) => <Button {...props} danger>오류신고</Button>}
      onDelete={() => {
        const data = formRef?.current?.getData() || null
        onAddErrorReport(data)
      }}
      onModalClose={() => formRef?.current?.resetData({ title: '', content: '' })}
    />
  )
}

export default ErrorReport
