import _ from 'lodash'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
  from,
  ApolloLink
} from '@apollo/client'
import { RetryLink } from "@apollo/client/link/retry"
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { setContext } from '@apollo/client/link/context'
import { createClient } from 'graphql-ws'
import { useEffect, useState } from 'react'
import { message } from 'antd'

import NetworkError from './components/NetworkError'

const url = 'https://case-api.seoulbar.or.kr:4445/graphql'
const wsUrl = 'wss://case-api.seoulbar.or.kr:4445/graphql'

// const url = 'http://43.202.16.124:4445/graphql'
// const wsUrl = 'ws://43.202.16.124:4445/graphql'

// const url = 'http://10.138.26.63:4445/graphql'
// const wsUrl = 'ws://10.138.26.63:4445/graphql'
// const url = 'http://mycase-api.notesquare.co.kr/graphql'
// const wsUrl = 'ws://mycase-api.notesquare.co.kr/graphql'

const getApolloClient = (token, retryLink, errorLink, connectionCheckMiddleWare) => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token
      }
    }
  })
  const httpLink = new HttpLink({ uri: url })
  const timeStartLink = new ApolloLink((operation, forward) => {
    operation.setContext({ start: new Date() })
    return forward(operation)
  })

  const _httpLink = from([
    authLink,
    errorLink,
    retryLink,
    timeStartLink,
    connectionCheckMiddleWare,
    httpLink
  ])
  const wsClient = createClient({
    url: wsUrl,
    connectionParams: {
      authorization: token,
    }
  })
  const wsLink = new GraphQLWsLink(wsClient)

  wsClient.on('opened', () => console.log('wsOpened'))
  wsClient.on('error', () => console.log('wsError'))
  wsClient.on('closed', () => console.log('wsClosed'))

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.operation === 'subscription'
    },
    wsLink,
    _httpLink
  )
  const cache = new InMemoryCache()
  const client = new ApolloClient({
    link: splitLink,
    cache
  })
  return client
}

function AuthWrapper ({ children }) {
  const [networkError, setNetworkError] = useState()
  const [networkLoading, setNetworkLoading] = useState()

  useEffect(() => {
    if (networkLoading) message.loading('서버와 연결중입니다.', 0)
    else message.destroy()
  }, [networkLoading])

  // check if url contains token
  const url = new URL(window.location)
  const params = new URLSearchParams(url.search)

  let token = params.get('token')
  if (token) {
    window.sessionStorage.setItem('token', token)
    url.searchParams.delete('token')
    window.location.replace(url)
    return
  } else {
    // make sure session Storage has token
    token = window.sessionStorage.getItem('token')
    const length = window.sessionStorage.length
    const arr = Array(length)
    arr.forEach((v, index) => {
      const item = window.sessionStorage.key(index)
      if (item !== 'token') {
        window.sessionStorage.removeItem(item)
      }
    })
  }

  if (!token) {
    return (
      window.location.replace('https://www.seoulbar.or.kr/api/sso_to_mycase.jsp')
    )
  }

  // custom links for error and reconnection
  const retryLink = new RetryLink({
    attempts: {
      max: 3,
      retryIf: (error, operation) => {
        if (error) {
          setNetworkLoading(true)
          setNetworkError(false)
        }
        return !!error
      }
    },
    delay: {
      initial: 3000,
      max: Infinity,
      jitter: true
    }
  })
  const errorLink = onError(({ graphQLErrors, networkError: _networkError }) => {
    console.log('graphQLErrors', graphQLErrors)
    console.log('_networkError', _networkError)
    if (_.get(graphQLErrors, [0, 'extensions', 'code']) === 'UNAUTHENTICATED') {
      window.location.replace('https://www.seoulbar.or.kr/api/sso_to_mycase.jsp')
    }
    if (_networkError) {
      setNetworkLoading(false)
      setNetworkError(_networkError)
    }
  })
  const connectionCheckMiddleWare = new ApolloLink((operation, forward) => {
    return forward(operation).map((val) => {
      if (val && (networkLoading || networkError)) {
        setNetworkLoading(false)
        setNetworkError(false)
      }
      const time = new Date() - operation.getContext().start
      console.log(`operation ${operation.operationName} took ${time} to complete`)
      return val
    })
  })
  const client = getApolloClient(token, retryLink, errorLink, connectionCheckMiddleWare)

  if (networkError) return <NetworkError />

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default AuthWrapper
