import _ from 'lodash'
import { Table } from 'antd'
import { useQuery } from '@apollo/client'

import { faqList } from '../../../../graphql/queries'

const FaqForm = ({ onClickRow, userQueryRefetch }) => {
  // quries
  const { data: faqListData } = useQuery(faqList, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      userQueryRefetch()
    }
  })

  // handlers
  const handleRow = (record) => {
    return {
      onClick: () => {
        onClickRow(record.title, record.faqId)
      }
    }
  }

  // variables
  const faqItems = _.get(faqListData, ['faqList'], [])
  const faqColumns = [
    {
      title: '번호',
      dataIndex: 'number'
    },
    {
      title: '제목',
      dataIndex: 'title'
    }
  ]

  return (
    <Table
      size='small'
      onRow={handleRow}
      pagination={false}
      columns={faqColumns}
      dataSource={faqItems}
      style={{ cursor: 'pointer' }}
    />
  )
}

export default FaqForm
