import _ from 'lodash'

import { message, Typography } from 'antd'
import { Buffer } from 'buffer'
import axios from 'axios'
import { useState } from 'react'

const DownloadLink = () => {
  const [disabled, setDisabled] = useState(false)
  return (
    <Typography.Link
      underline
      style={{ cursor: 'pointer' }}
      disabled={disabled}
      onClick={async () => {
        const params = new URLSearchParams()
        const token = sessionStorage.getItem('token')
        params.append('token', token)
        const link = document.createElement('a');
        link.download = '사건목록.xlsx'
        message.loading('파일 다운로드 중')
        setDisabled(true)
        const res = await axios.get(
          'https://dya7nuu48j.execute-api.ap-northeast-2.amazonaws.com/casemgnt_download_excel',
          { params }
        )
        if (_.get(res, ['status']) === 200) {
          const buffer = new Buffer.from(_.get(res, ['data']), 'base64')
          var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' })
          const blobUrl = window.URL.createObjectURL(blob)
          link.href = blobUrl
          link.click()
          URL.revokeObjectURL(blobUrl)
          message.destroy()
          setDisabled(false)
        } else {
          message.destroy()
          message.error('다운로드 실패')
          link.click()
          setDisabled(false)
        }

      }}
    >
      엑셀로 저장하기
    </Typography.Link>
  )
}

export default DownloadLink
