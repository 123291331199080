import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;

  #container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 80%;
  }

  #stub {
    margin-top: 75%;
  }

  #container2 {
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
  }
`

const WidthHeightResizableContainer = ({ children, id }) => {
  return (
    <Container id={id}>
      <div id='container'>
        <div id='stub'></div>
        <div id='container2'>
          {children}
        </div>
      </div>
    </Container>
  )
}

export default WidthHeightResizableContainer
