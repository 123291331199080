import { Grid } from 'antd'
import { isMobile } from 'react-device-detect'

const { useBreakpoint } = Grid

export const useWindowSize = () => {
  const { xs, sm, lg } = useBreakpoint()
  let windowSize = null
  if (isMobile) windowSize = 'small'
  else if (lg) {
    windowSize = 'large'
  } else if (sm) {
    windowSize = 'middle'
  } else if (xs) {
    windowSize = 'small'
  }
  return { windowSize }
}
