import React from 'react';
import { Carousel } from 'antd';

const defContentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const CustomCarousel = ({ className, contentStyle = defContentStyle, dotsClassName, children, width }) => (
  <Carousel autoplay className={className} style={{ width }} dots={{ className: dotsClassName }}>
    {children}
  </Carousel>
);
export default CustomCarousel;