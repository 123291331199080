import { Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`

const NavBack = ({ onNavBack, title, className }) => {
  return (
    <div onClick={onNavBack} style={{ cursor: 'pointer', display: 'flex' }}>
      <HeaderContainer className={className}>
        <LeftOutlined />
        <Typography.Text strong>{title}</Typography.Text>
      </HeaderContainer>
    </div>
  )
}

export default NavBack
