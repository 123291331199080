import { gql } from '@apollo/client'

export const caseUpdated = gql`
  subscription CaseUpdated($caseId: Int) {
    caseUpdated(caseId: $caseId) {
      status
      message
    }
  }
`

export const subTest = gql`
  subscription Subscription($data: String) {
    subTest(data: $data)
  }
`
