import _ from 'lodash'
import { Input, Button, Dropdown } from 'antd'
import styled from 'styled-components'
import React, { useState, useRef, useContext, useEffect } from 'react'

import { ContentWithTitle } from '../../../common'
import { courts } from '../../../../config'
// import { ModalOpenContext } from '../AddCaseModalContextProvider'

const { Search } = Input

const Container = styled.div`
  > div {
    margin-bottom: 10px;
    gap: 10px 5px;
  }
  max-width: 300px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background: white;
  padding: 10px;
`
const HLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px #999 dashed;
`
const focusButton = (index) => {
  const courtsTootlipElem = document.getElementById('courts')

  if (courtsTootlipElem) {
    const buttons =_.reduce(courtsTootlipElem.children, (prev, curr, index) => {
      if (index % 2 === 1) {
        return [...prev, ...curr.children]
      } else return prev
    }, [])

    const targetButton = _.get(buttons, [index], null)
    if (targetButton) {
      targetButton.focus()
    }
    
    return buttons.length
  }
}
const CourtSelect = ({ value: selectedCourt, onSelect = () => {}, placeholder = '', focusNext, id, isOpened }) => {
  // console.log(isOpened)
  const searchRef = useRef(null)
  const inputRef = useRef(null)
  const [isTooltipOpened, setIsTooltipOpened] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)

  // for court select key down event
  const [focusIndex, setFocusIndex] = useState(0)

  // const { open } = useContext(ModalOpenContext)
  // useEffect(() => {
  //   if (!isOpened) {
  //     setSearchQuery(null)
  //     setIsTooltipOpened(false)
  //     setFocusIndex(0)
  //   }
  // }, [isOpened])

  // handlers
  const onClickTag = async (val) => {
    onSelect(val)
    setIsTooltipOpened(false)
    focusNext()
  }
  const onSearch = e => setSearchQuery(e.target.value)

  // various keyboard handlers
  const onCourtButtonArrowMovement = (e) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
      setFocusIndex(focusIndex => {
        const length = focusButton(focusIndex + 1)
        if (length - 1 === focusIndex) {
          return focusIndex
        }
        return focusIndex + 1
      })
    } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
      setFocusIndex(focusIndex => {
        if (focusIndex === 0) {
          if (searchRef.current) searchRef.current.focus()
          return focusIndex
        }
        focusButton(focusIndex - 1)
        return focusIndex - 1
      })
    }
  }
  const onArrowMovementFromInputToCourtButtons = (e) => {
    if (e.key === 'ArrowDown') focusButton(0)
  }
  const onAutoSelectFirstCourtWhenPressEnter = () => {
    if (_.isEmpty(filtered)) return
    const autoSelectedFirstOption = _.get(filtered, [0, 'children', 0])
    onClickTag({ value: autoSelectedFirstOption.code, label: autoSelectedFirstOption.name })
  }

  let filtered = courts
  if (searchQuery) {
    filtered = _.flatMap(courts, ({ name, children }) => {
      const filteredChildren = _.filter(children, ({ name }) => name.indexOf(searchQuery) !== -1)
      if (_.size(filteredChildren) > 0) {
        return [{
          name,
          children: filteredChildren
        }]
      }
      return []
    })
  }

  const renderContent = (filtered) => (
    <Container id='courts'>
      {_.map(filtered, ({ name, children = [] }) => (
        <ContentWithTitle key={name}>
          {_.map(children, ({ code, name }) => (
              <Button
                key={code}
                value={code}
                id={`${code}_${name}`}
                onClick={() => onClickTag({ value: code, label: name })}
                onKeyDown={onCourtButtonArrowMovement}
              >
                {name}
              </Button>
            ))}
          <HLine />
        </ContentWithTitle>
      ))}
    </Container>
  )

  return (
    <Dropdown
      open={isTooltipOpened}
      trigger='click'
      placement='bottom'
      onOpenChange={(e) => {
        setSearchQuery(null)
        setIsTooltipOpened(false)
        setFocusIndex(0)
      }}
      dropdownRender={() => renderContent(filtered)}
    >
      {isTooltipOpened
        ? (
          <Search
            ref={searchRef}
            allowClear
            autoFocus
            value={searchQuery}
            onChange={onSearch}
            onFocus={() => setFocusIndex(0)}
            onKeyDown={onArrowMovementFromInputToCourtButtons}
            onPressEnter={onAutoSelectFirstCourtWhenPressEnter}
          />
        ) : (
        <Input
          id={id}
          ref={inputRef}
          placeholder={placeholder}
          value={_.get(selectedCourt, ['label'], null)}
          onFocus={() => setIsTooltipOpened(true)}
        />)}
    </Dropdown>
  )
}

export default CourtSelect
