import { Table, Typography } from 'antd'
import styled from 'styled-components'

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .ant-table-cell-ellipsis {
    vertical-align: top;
  }
`

const CaseEventTable = ({ id, data }) => {
  return (
    <ColumnContainer id={id}>
      <Table
        size='small'
        pagination={false}
        columns={[
          { dataIndex: 'date', title: '날짜', width: 110, ellipsis: true },
          { dataIndex: 'info', title: '', render: (info) => <Typography.Text style={{ whiteSpace: 'pre-line' }}>{info}</Typography.Text> }
        ]}
        dataSource={data}
        title={() => <Typography.Title level={5}>사건 연혁</Typography.Title>}
      />
    </ColumnContainer>
  )
}

export default CaseEventTable
