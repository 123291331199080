import _ from 'lodash'
import { Tag, Table } from 'antd'
import styled from 'styled-components'

import { eventsTableColumns } from './tableColumns'
import { RadioSelect } from '../common'
import TableTitleWithSort from './TableTitleWithSort'
import TableTitle from './TableTitle'
import { getScrollbarWidth } from '../../config'

const ColumnContainer = styled.div`
  flex-direction: column;
  z-index: 0;
  width: 100%;
  display: flex;
  .table-title {
    padding: 8px;
  }
  .multiline {
    white-space: pre-line;
  }
`

const EventDateTable = (props) => {
  const {
    id,
    data,
    size,
    filter,
    sortKey,
    sortOrder,
    windowSize,
    onSort,
    onFilter
  } = props

  const colorcode = {
    '기일': 'magenta',
    '명령': 'gold',
    '제출서류': 'blue',
    '송달': 'purple',
  }

  const columns = _.map(eventsTableColumns, (_column, i) => {
    let column = _column

    if (i === 0) {
      column = { ...column, fixed: windowSize === 'small' && 'left' }
    } else if (i === eventsTableColumns.length - 1) {
      column = { ...column, fixed: windowSize === 'small' && 'right' }
    }

    if (column.dataIndex === 'type') {
      return { ...column, render: v => v && <Tag color={_.get(colorcode, [v], '')}>{v}</Tag> }
    } else if (column.dataIndex === 'date') {
      return { ...column, title: () => (
        <TableTitleWithSort
          title={column.title}
          sortOrder={sortKey === column.dataIndex ? sortOrder : null}
          onUp={() => onSort('up', 'date')}
          onDown={() => onSort('down', 'date')}
        />
      ) }
    } else return column
  })

  // Custom rename rules
  // if type == '기일' && result == '기일변경' then reset type
  // const dataUpdated = _.map(data, _data => {
  //   const { type, result, ...values  } = _data
  //   // let _result = result
  //   // if (type === '기일' && result === '기일변경') {
  //   //   _result = ''
  //   // }
  //   return { type, ...values  }
  // })
  return (
    <ColumnContainer id={id} scrollBarWidth={getScrollbarWidth()}>
      <TableTitle title='사건 연혁' className='table-title'>
        <RadioSelect
          options={['전체', '기일', '명령', '제출서류', '송달']}
          value={filter}
          size='small'
          onChange={(val) => onFilter(val)}
        />
      </TableTitle>
      <Table
        size={size}
        columns={columns}
        pagination={false}
        dataSource={data}
        scroll={{ x: windowSize === 'small' ? 'calc(500px + 50%)' : false }}
      />
    </ColumnContainer>
  )
}

export default EventDateTable
