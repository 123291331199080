import { Typography } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-diretion: column;
  flex-wrap: wrap;
  gap: 10px 0px;
`

const ContentWithTitle = ({ title, children }) => {
  return (
    <>
      <Typography.Title level={5}>{title}</Typography.Title>
      <Container>
        {children}
      </Container>
    </>
  )
}

export default ContentWithTitle
