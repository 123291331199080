import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const AddCaseButton = ({ windowSize, onClick }) => {
  return (
    <>
      {windowSize === 'large'
        ? (
          <Button onClick={onClick} type='primary' ghost icon={<PlusOutlined />}>
            사건 추가하기
          </Button>)
        : (
          <Button onClick={onClick} type='link' style={{ height: 'auto', width: 'auto', padding: 0 }} size='large' icon={<PlusOutlined />} />
        )}
    </>
  )
}

export default AddCaseButton
