import _ from 'lodash'
import styled from 'styled-components'
import { Button, Space } from 'antd'
import { useNavigate, useParams, useOutletContext, useLocation } from 'react-router-dom'

import useScrollTop from '../hook/useScrollTop'
import Responsive from '../components/Responsive'
import { RadioSelect } from '../components/common'
import RouteName from '../components/RouteName'
import useCaseList from '../hook/useCaseList'
import { useEffect } from 'react'
import DownloadLink from '../components/common/DownloadLink'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  gap: 10px;
  max-width: 1400px;
  z-index: 0;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CasesContainer = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { searchId, datestring, caseId } = useParams()
  const [viewMode, setViewMode] = useOutletContext()

  // hook
  const isHome = !searchId && !datestring && !caseId
  const { scrollTop, updateScrollTop } = useScrollTop()
  useEffect(() => {
    if (isHome) {
      sessionStorage.setItem('scrollTop', scrollTop)
    }
  }, [scrollTop])
  useEffect(() => {
    if (isHome) {
      updateScrollTop(sessionStorage.getItem('scrollTop') || 0)
    }
  }, [isHome])
  const casesPerPage = 50
  const {
    cases,
    total,
    loading,
    sortKey,
    sortOrder,
    initLoading,
    sort,
    viewMore
  } = useCaseList(datestring,
    searchId,
    caseId,
    viewMode,
    casesPerPage
  )

  // handlers
  const onAdd = () => viewMore()
  const onNavToDetailScreen = caseNum => navigate(`/detail/${caseNum}`)

  // variables
  const sortProps = {
    sortKey: sortKey,
    sortOrder: sortOrder,
    onSort: sort
  }
  const viewModeSetterOptions = [
    {
      label: '진행 중',
      value: 'progress',
    },
    {
      label: '전체사건',
      value: 'whole',
    }
  ]

  return (
    <ContentContainer>

      <RowContainer>
        <RouteName />
        {pathname.includes('detail')
          ? null
          : (
              <RadioSelect
                className='setActiveOnly'
                value={viewMode}
                options={viewModeSetterOptions}
                onChange={val => setViewMode(val)}
              />)}     
      </RowContainer>

      <Responsive
        id='caseTable'
        data={cases}
        loading={initLoading}
        datestring={datestring}
        onNav={onNavToDetailScreen}
        {...sortProps}
      />
      
      <Space direction='vertical' style={{ marginTop: 20, alignSelf: 'center' }}>
        {cases.length < total ? (
          <Button
            style={{ borderRadius: 10 }}
            onClick={onAdd}
            loading={loading}
            disabled={loading}
          >
            더보기 ({cases.length} / {total})
          </Button>) : (
            <p>총 {total}개 사건을 불러왔습니다.</p>
          )}
      </Space>

      <DownloadLink />

    </ContentContainer>
  )
}

export default CasesContainer
