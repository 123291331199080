import { Radio } from 'antd'

const RadioSelect = ({ options, value, onChange, className, size }) => {
  return (
    <Radio.Group
      className={className}
      style={{ margin: 3 }}
      options={options}
      optionType='button'
      buttonStyle='solid'
      size={size}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
    />
  )
}

export default RadioSelect
