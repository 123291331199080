import styled from "styled-components"

const RedDot = styled.div`
  height: 6px;
  width: 6px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
`

export default RedDot
