import styled from 'styled-components'
import { NavBack } from '../../common'

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  padding: 0px 10px;
`

const NavBackHeader = ({ title, onNavBack }) => {
  return (
    <Container>
      <NavBack title={title} onNavBack={onNavBack} />
    </Container>
  )
}

export default NavBackHeader
