import { Button, Divider, Input, Modal, Space, Typography } from 'antd'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {  MoreOutlined } from '@ant-design/icons'
import _ from 'lodash'

import { textByStatus } from '../../../config'
import { FormList, QuillEditor, RadioSelect } from '../../common'
import DeleteCaseButton from './DeleteCaseButton'

const Container = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: space-between;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  .ql-container {
    height: 300px;
    border: 1px solid #ccc;
  }
  > * {
    gap: 10px;
    height: 500px;
    .ant-typography {
      font-size: 13px;
      margin: 0;
    }
  }
`

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const CaseControl = ({ onEditFinish, initManagementData, status: defaultStatus = 'ACTIVE', onSetUserCase, onDeleteUserCase }) => {
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState(defaultStatus)
  useEffect(() => {
    setStatus(defaultStatus)
  }, [defaultStatus])
  const formRef = useRef(null)

  useEffect(() => {
    if (formRef.current) formRef.current.resetData(initManagementData)
  },[open])

  const formEditItems = [
    {
      key: 'managementName',
      title: '관리명칭',
      render: (props) => (
        <Input
          {...props}
          placeholder='관리명칭을 추가하세요'
        />),
        dataParser: e => e.target.value
      },
    {
      key: 'memo',
      title: '',
      class: 'ql-container',
      render: (props) => (
        <div style={{ width: '100%' }}>
          <QuillEditor
            style={{ width: '100%', innerHeight: 400 }}
            placeholder='내용을 추가하세요.'
            imageDisabled
            {...props}
          />
        </div>
      )
    }
  ]

  const handleSave = () => {
    setOpen(false)
    const data = formRef?.current?.getData()
    onEditFinish(data)
  }
  return (
    <>
      <Container id='case-control'>
        
        <Typography.Title level={5} style={{ margin: '0' }}>사건정보</Typography.Title>

        <Space>
          <RadioSelect
            value={status}
            size='small'
            onChange={(e) => {
              setStatus(e)
              onSetUserCase(e)
            }}
            options={_.map(_.filter(_.keys(textByStatus), val => val !== 'PENDING' && val !== 'INVALID'), (key) => ({ value: key, label: textByStatus[key] }))}
          />
          <MoreOutlined onClick={() => setOpen(true)} rotate={90} />
        </Space>
    
        <Modal id='memo-modal' open={open} onCancel={() => setOpen(false)} footer={null}>
          <FormContainer>
            <FormList
              ref={formRef}
              items={formEditItems}
              initData={initManagementData}
              onDismiss={() => setOpen(false)}
              footerless
            />
          </FormContainer>

          <Divider />
          
          <RowContainer>
            <DeleteCaseButton onDelete={onDeleteUserCase} />
            <Button onClick={handleSave} type='primary' disabled={status === 'PENDING'}>저장하기</Button>
          </RowContainer>
        </Modal>

      </Container>
    </>
  )
}

export default CaseControl
