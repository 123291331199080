import _ from 'lodash'
import styled from 'styled-components'

import HTMLContent from '../../common/HTMLContent'
import { CustomCarousel } from '../../common'
import WidthHeightResizableContainer from '../../common/WidthHeightResizableContainer'
// import { useQuery } from '@apollo/client'
// import { banners } from '../../../graphql/queries'

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  .dots {
    display: block;
    background: #6d7f8e;
    padding: 10px;
    bottom: -10px;
  }
  #resizable {
    max-width: 1000px;
  }
  .banner {
    width: 100%;
  }
`

const BannerForm = ({ bannerList }) => {
  // const { data: bannersData } = useQuery(banners)
  // const bannerList = _.get(bannersData, ['banners'], [])

  return (
    <Container>
      <CustomCarousel dotsClassName='dots'>
        {bannerList.map((val, index) => {
          const { type, data } = _.pick(val, ['type', 'data'])
          return (
            <>
              {/* <WidthHeightResizableContainer key={`container_${index}`} id='resizable'> */}
                {type === 'IMAGE'
                  ? (
                    <img id='banner' className='banner' src={data} />
                  )
                  :
                    type === 'HTML'
                      ? (
                        <HTMLContent id={`banner_${index}`} className='banner' data={data} />
                      )
                      : null}
              {/* </WidthHeightResizableContainer> */}
            </>
          )
        })}
      </CustomCarousel>
    </Container>
  )
}

export default BannerForm