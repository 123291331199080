import { useState } from 'react'
import { Space, Button, Modal } from 'antd'
import styled from 'styled-components'

const ButtonContainer = styled.div`
`

const DeleteCaseButton = ({ onDelete }) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const onClickDeleteButtonPopModal = () => setDeleteModalVisible(true)
  const onOk = () => {
    onDelete()
    setDeleteModalVisible(false)
  }

  const onCancel = () => setDeleteModalVisible(false)

  const footer = (
    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={onCancel}>취소</Button>
      <Button danger onClick={onOk}>삭제하기</Button>
    </Space>
  )

  return (
    <ButtonContainer className='deleteCaseButton'>
      <Button danger onClick={onClickDeleteButtonPopModal}>사건 삭제하기</Button>
      <Modal open={deleteModalVisible} onCancel={onCancel} onOk={onOk} footer={footer}>
        정말 삭제하시겠습니까?
      </Modal>
    </ButtonContainer>
  )
}

export default DeleteCaseButton
