import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'

import { parseDate } from '../config'
import * as queries from '../graphql/queries'

const useCaseList = (datestring, searchId, caseId, viewMode, casesPerPage) => {
  // states
  const prevData = JSON.parse(sessionStorage.getItem('prevData'))
  const initData = _.get(prevData, ['data'])
  const [data, setData] = useState(initData || { whole: [], progress: [] })
  const [total, setTotal] = useState({ whole: 0, progress: 0 })
  const [page, setPage] = useState({ whole: 1, progress: 1 })
  const [sortMethod, setSortMethod] = useState({ ascending: true, key: 'nextCurrentEvent' })
  const [loading, setLoading] = useState(false)
  const { fetchMore } = useQuery(queries.userCaseList)

  // query variables
  const defFilter = { activeOnly: viewMode === 'progress' }
  const filter =
    datestring
      ? { ...defFilter, date: parseDate(datestring, 'YYYY-MM-DD') }
      : searchId ? { ...defFilter, caseNumberPattern: searchId } : defFilter
  const isHome = !datestring && !searchId && !caseId
  useEffect(() => {
    if (isHome && prevData) {
      // set previously saved data as home data
      setData(prevData.data)
      setPage(prevData.page)
      setTotal(prevData.total)
      setSortMethod(prevData.sortMethod)
    } else {
      setPage(page => {
        reset(sortMethod)
        return { whole: 1, progress: 1 }
      })
    }
  }, [datestring, searchId, caseId])

  const globData = { total, page, sortMethod, data }
  useEffect(() => {
    if (isHome) {
      if (!_.isEqual(globData, prevData)) {
        sessionStorage.setItem('prevData', JSON.stringify(globData))
      }
    }
  }, [globData])
  
  // fetchers
  const reset = async (_sortMethod) => {
    try {
      setLoading(true)
      const wholeVariables = {
        page: 1,
        filter: {...filter, activeOnly: false},
        sortMethod: _sortMethod,
        casesPerPage
      }
      const res1 = await fetchMore({
        variables: wholeVariables,
        fetchPolicy: 'network-only'
      })
      const whole = _.get(res1, ['data', 'userCaseList', 'data'], null)
      const progressVariables = {
        page: 1,
        filter: {...filter, activeOnly: true},
        sortMethod: _sortMethod,
        casesPerPage
      }
      const res2 = await fetchMore({
        variables: progressVariables,
        fetchPolicy: 'network-only'
      })
      const progress = _.get(res2, ['data', 'userCaseList', 'data'], null)
      if (whole && progress) {
        setData({ whole, progress })
        setTotal({
          whole: _.get(res1, ['data', 'userCaseList',  'total'], 0),
          progress: _.get(res2, ['data', 'userCaseList',  'total'], 0)
        })
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }
  const loadMore = async (_page) => {
    try {
      setLoading(true)
      const variables = {
        page: _page,
        filter,
        sortMethod,
        casesPerPage
      }
      const res = await fetchMore({
        variables,
        fetchPolicy: 'network-only'
      })
      if (res) {
        setTotal(total => ({...total, [viewMode]: _.get(res, ['data', 'userCaseList',  'total'], 0) }))
        setData(data => ({ ...data, [viewMode]: [..._.get(data, [viewMode], []), ..._.get(res, ['data', 'userCaseList', 'data'], [])]}))
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }
  
  // pagination & sorting handlers
  const viewMore = () => {
    setPage(page => {
      const newPage = page[viewMode] + 1
      loadMore(newPage)
      return { ...page, [viewMode]: newPage }
    })
  }
  const sort = (currSortOrder, key) => {
    const ascending = currSortOrder === 'up'
    if (sortMethod.ascending === ascending && sortMethod.key === key) return
    setSortMethod(sortMethod => {
      const newSortMethod = { ascending, key }
      setPage(page => {
        reset(newSortMethod)
        return { whole: 1, progress: 1 }
      })
      return newSortMethod
    })
  }

  // variables
  const cases = _.map(_.get(data, [viewMode]), (_data, index) => {
    const { caseNumber, name, court } = _data
    return {
      ..._data,
      key: `case_${index}`,
      name: `${court || ''} ${caseNumber || ''}\n${name || ''}`
    }
  })

  return {
    viewMore,
    initLoading: loading,
    cases: cases,
    loading,
    total: _.get(total, [viewMode], 0),
    sort,
    sortKey: _.get(sortMethod, ['key']),
    sortOrder: _.get(sortMethod, ['ascending'], null)
      ? 'up'
      : _.get(sortMethod, ['ascending'], null) === false
        ? 'down' : null
  }
}

export default useCaseList
