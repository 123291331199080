import { Typography } from 'antd'
import styled from 'styled-components'

import { logoBase64 } from '../config'

const RowContainer = styled.div`
  display: flex;
  gap: 3%;
  cursor: pointer;
  height: 32px;
  align-items: ${props => props.size === 'large' ? 'flex-end' : 'center'};

  h1 {
    margin: 0;
    white-space: pre;
    font-size: ${props => props.size === 'large' ? '22px' : '16px'};
  }
`
const Logo = ({ onClickTitle, size, children }) => {
  return (
    <RowContainer onClick={onClickTitle} size={size}>
      <img
        id='image'
        style={{ height: 31 }}
        src={size === 'large' || size === 'middle' ? 'https://www.seoulbar.or.kr/images/common/common/header_logo.gif' : logoBase64}
        alt='서울지방변호사회'
      />
      <Typography.Title level={1}>사건관리 BETA</Typography.Title>
      {children}
    </RowContainer>
  )
}

export default Logo
