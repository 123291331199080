import { useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import AddCaseForm from './form'
import AddCaseHeader from './AddCaseHeader'
import ResponsiveModal from '../ResponsiveModal'
import AddCaseFooter from './AddCaseFooter'
import useAddCase from '../../../hook/useAddCase'
import AddCaseButton from './AddCaseButton'

const AddCaseResponsive = ({ didUserAgree, onOpenChange, windowSize, isButtonHidden }) => {
  // states
  const modalRef = useRef(null)
  const [caseInfo, setCaseInfo] = useState(null)
  const [isModalOpened, setIsModalOpened] = useState(null)
  const [policyShownIfUserIsntAgreed, setPolicyShownIfUserIsntAgreed] = useState(false)

  // custom hook handling adding case
  let skipSub = !isModalOpened
  const closeModal = () => modalRef?.current?.close()
  const { addCase, loading, errorMessage, failure } = useAddCase(skipSub, closeModal)
  
  // reset state when modal is closed
  useEffect(() => {
    if (!isModalOpened) setCaseInfo(null)
    onOpenChange(isModalOpened)
  }, [isModalOpened])

  // handlers
  const handleModalOpenChange = (e) => setIsModalOpened(e)
  const handleCancel = () => {
    closeModal()
    setPolicyShownIfUserIsntAgreed(false)
    setCaseInfo(null)
  }
  const handleSave = () => {
    if (didUserAgree) {
      addCase(caseInfo)
    } else if (policyShownIfUserIsntAgreed) {
      setPolicyShownIfUserIsntAgreed(false)
      addCase(caseInfo)
    } else {
      setPolicyShownIfUserIsntAgreed(true)
    }
  }
  const handleChangeForm = (val, key) => 
    setCaseInfo(caseInfo => {
      return {
        ...caseInfo,
        [key]: val
      }
    })

  // variables
  const isShowingPolicy = policyShownIfUserIsntAgreed && !didUserAgree
  const title = isShowingPolicy ? '사건관리 서비스 운영약관' : '사건 등록 하기'

  // renderers
  const renderFooter = () => {
    const registerDisabled = !_.get(caseInfo, ['caseNumber']) || !_.get(caseInfo, ['court']) || !_.get(caseInfo, ['partyName'])
    return (
      <AddCaseFooter
        onSave={handleSave}
        onCancel={handleCancel}
        isUserAgreeing={isShowingPolicy}
        disabled={registerDisabled}
      />
    )
  }
  const renderHeader = () => (
    <AddCaseHeader
      saveTitle={isShowingPolicy ? '동의하고 등록하기' : '등록하기'}
      title={title}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  )
  const renderButton = (props) => {
    if (isButtonHidden) return null
    return (
      <><AddCaseButton windowSize={windowSize} {...props} /></>
    )
  }
   
  return (
    <ResponsiveModal
      ref={modalRef}
      title={title}
      renderFooter={renderFooter}
      renderHeader={renderHeader}
      renderButton={renderButton}
      onOpenChange={handleModalOpenChange}
      style={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}
    >
      <AddCaseForm
        loading={loading}
        failure={failure}
        caseInfo={caseInfo}
        isOpened={isModalOpened}
        errorMessage={errorMessage}
        showPolicy={isShowingPolicy}
        onChangeForm={handleChangeForm}
      />
    </ResponsiveModal>
  )
}

export default AddCaseResponsive
