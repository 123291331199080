import { Input } from 'antd'

import { CustomForm } from '../../../common'
import CourtSelect from './CourtSelect'
const TEXT_WIDTH = 80

const ContentRow = ({ id, title, value, onChangeInput, placeholder, focusNext, isOpened }) => {
  return (
    <CustomForm
      gap={30}
      title={title}
      textWidth={TEXT_WIDTH}
    >
      {title === '법원'
        ? (<CourtSelect
            id={id}
            placeholder={placeholder}
            focusNext={focusNext}
            isOpened={isOpened}
            value={value}
            onSelect={(val) => onChangeInput(val)}
          />)
        : <Input
            id={id}
            value={value}
            placeholder={placeholder}
            onPressEnter={focusNext}
            onChange={(e) => onChangeInput(e.target.value)}
          />}
    </CustomForm>
  )
}

export default ContentRow
