import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import Calendar from 'react-calendar'
import { Spin, Typography } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import '../../Calendar.css'
import { CalendarTile } from '../common'
import { getYearMonthFromDate, addZeroToMonthOrDay } from '../../config'
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useQuery } from '@apollo/client'
import { calendarEvents } from '../../graphql/queries'
dayjs.locale('ko');
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > * + * {
    align-self: flex-start;
  }
`
const SingleCalendarContainer = styled.div`
  display: flex;
  width: ${props => props.size === 'middle' ? '60%' : '100%'};
  flex-direction: column;
`
const MultipleCalendarsContainer = styled.div`
  display: flex;
  justify-content: ${props => props.selected ? 'center' : 'flex-start'};
  max-width: 1400px;
`
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0.5em 1em;
  .react-calendar__tile--active  {
    background: ${props => props.selected ? undefined : 'none'};
    color: ${props => props.selected ? 'white' : undefined};
  }
  > * {
    align-self: center;
  }
  .year-month-text {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    display: flex;
  }
`
const ArrowIconContainer = styled.div`
  align-self: center;
  font-size: 20px;
  display: flex;
  flex: 0.1;
  margin: 5px;
  width: 20px;
`
const LoadingContainer = styled.div`
  z-index: 10;
  position: absolute;
  align-self: center;
  display: flex;
  width: 100%;
  justify-content: center;
`

const ARROW_BUTTON_SIZE = 30

function CalendarResponsive (props) {
  const {
    windowSize,
    selectedDate,
    onChangeCalendarDate
  } = props
  const { data, loading } = useQuery(calendarEvents)
  const markedDates = _.get(data, ['calendarEvents'], [])

  const date = selectedDate || new Date()
  const initYearMonth = getYearMonthFromDate(date)
  const [yearMonthToShow, setYearMonthToShow] = useState(initYearMonth)
  const year = parseInt(_.split(yearMonthToShow, '-')[0])
  const month = parseInt(_.split(yearMonthToShow, '-')[1])
  let prev = null
  let next = null
  if (month === 1) {
    prev = `${year - 1}-12`
    next = `${year}-02`
  } else if (month === 12) {
    prev = `${year}-11`
    next = `${year + 1}-01`
  } else {
    prev = `${year}-${addZeroToMonthOrDay(month - 1)}`
    next = `${year}-${addZeroToMonthOrDay(month + 1)}`
  }
  const calendarItems = selectedDate ? [getYearMonthFromDate(selectedDate)] : [prev, yearMonthToShow, next]
  const selected = !!selectedDate
  const renderTile = props => <CalendarTile {...props} markedDates={markedDates} />
  if (windowSize === 'large') {
    return (
      <MultipleCalendarsContainer selected={selected}>
        {loading && <LoadingContainer id='test'><Spin /></LoadingContainer>}
        <ArrowIconContainer>
          {!selectedDate && (
            <LeftOutlined
              style={{ fontSize: ARROW_BUTTON_SIZE }}
              onClick={() => setYearMonthToShow(prev)}
            />)}
        </ArrowIconContainer>

        {calendarItems.map((yearMonth, index) => {
          // deal with rendering bug when selecting date
          const yearMonthText = `${_.split(yearMonth, '-')[0]}년 ${parseInt(_.split(yearMonth, '-')[1])}월`
          const value = selectedDate || new Date(yearMonth)
          const calendarProps = selected ? { value: selectedDate, activeStartDate: selectedDate } : { activeStartDate: new Date(dayjs(yearMonth)) }
          if (value) {
            return (
              <ColumnContainer key={`calendar_${index}`} selected={selectedDate}>
                <Typography.Text className='year-month-text'>{yearMonthText}</Typography.Text>
                <Calendar
                  locale='ko'
                  {...calendarProps}
                  showNavigation={false}
                  showNeighboringMonth={false}
                  tileContent={renderTile}
                  onChange={onChangeCalendarDate}
                />
              </ColumnContainer>
            )
          }

        })}

        <ArrowIconContainer>
          {!selectedDate && (
            <RightOutlined
              style={{ fontSize: ARROW_BUTTON_SIZE }}
              onClick={() => setYearMonthToShow(next)}
            />)}
        </ArrowIconContainer>

      </MultipleCalendarsContainer>
    )
  }
  return (
    <Container>

      <SingleCalendarContainer size={windowSize}>
        <Calendar
          locale='ko'
          value={selectedDate}
          tileContent={renderTile}
          defaultActiveStartDate={date}
          showNeighboringMonth={false}
          onActiveStartDateChange={({ activeStartDate }) => setYearMonthToShow(getYearMonthFromDate(activeStartDate))}
          onChange={onChangeCalendarDate}
        />
      </SingleCalendarContainer>

    </Container>
  )
}

export default CalendarResponsive
