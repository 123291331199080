import { Typography } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`

const AddCaseHeader = ({ onCancel, onSave, title, saveTitle = '저장' }) => {
  return (
    <Container>
      <Typography.Link onClick={onCancel}>취소</Typography.Link>
      <Typography.Title level={3} style={{ margin: 0 }}>{title}</Typography.Title>
      <Typography.Link onClick={onSave}>{saveTitle}</Typography.Link>
    </Container>
  )
}

export default AddCaseHeader
