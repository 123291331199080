import _ from 'lodash'
import { Divider } from 'antd'
import React from 'react'
import styled from 'styled-components'

import { userModalItems } from '../../../../../config'
import LinkToSubForm from './LinkToSubForm'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`

const ColumnContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  .info-button {
    width: 100%;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    > * {
      padding: 5px;
    }
  }
`

const BaseForm = ({ onClickRow, isUnreadByTitle, onDeleteAllCases, onWithdrawFromService }) => {
  return (
    <Container>
      {userModalItems.map((items, index) => {
        return (
          <ColumnContainer key={`formLink_${index}`}>
            {items.map(item => {
              return (
                <LinkToSubForm
                  {...item}
                  key={item.title}
                  isUnread={isUnreadByTitle[item.title]}
                  // onDeleteAllCases={onDeleteAllCases}
                  // onWithdrawFromService={onWithdrawFromService}
                  onClick={() => {
                    onClickRow(_.get(item, ['title']))
                  }}
                />)
            })}
            {index === userModalItems.length - 1 ? null : <Divider />}
          </ColumnContainer>
        )
      })}
    </Container>
  )
}

export default BaseForm
