import { useEffect, useState } from 'react'
import { Space, Button, Modal } from 'antd'
import styled from 'styled-components'

const ButtonContainer = styled.div`
`

const DeleteButton = ({ deleteText, render, onDelete, className, icon, modalText = '정말 삭제하시겠습니까?', footerText = '삭제하기', onModalClose = () => {} }) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  useEffect(() => {
    if (!deleteModalVisible) onModalClose()
  }, [deleteModalVisible])
  const onClickDeleteButtonPopModal = () => setDeleteModalVisible(true)
  const onOk = () => {
    onDelete()
    setDeleteModalVisible(false)
  }

  const onCancel = () => setDeleteModalVisible(false)
  const Child = (props) => render(props)
  const footer = (
    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button onClick={onCancel}>취소</Button>
      <Button danger onClick={onOk}>{footerText}</Button>
    </Space>
  )

  return (
    <>
      {render
        ? <Child onClick={onClickDeleteButtonPopModal} />
        : <Button icon={icon} className={className} onClick={onClickDeleteButtonPopModal}>{deleteText}</Button>}
      <Modal open={deleteModalVisible} onCancel={onCancel} onOk={onOk} footer={footer}>
        {modalText}
      </Modal>
    </>
  )
}

export default DeleteButton
