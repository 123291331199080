import _ from 'lodash'
import styled from 'styled-components'
import { Table, Tag, Typography } from 'antd'

import { parseDateTime, eventColorcode, textByStatus, parseDate } from '../../config'
import TableTitleWithSort from './TableTitleWithSort'
import { DropDownSelect } from '../common'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .multiline {
    white-space: pre-line;
  }
  .dropDown {
    width: 100px;
  }
`
const PartyList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

const PartyContainer = styled.div`
  display: flex;
  flex-direction: row;
`
const NextEventContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Party = ({ type, name }) => {
  let _type = type
  if (type === '피고인명') {
    _type = '피고인'
  }
  return (
    <PartyContainer>
      {_type && (<Tag style={{ height: 'fit-content' }}>{_type}</Tag>)}
      {name && (<Typography.Text className='multiline' ellipsis>{name}</Typography.Text>)}
    </PartyContainer>
  )
}
const CaseTable = ({ data, onNav, onSort, sortOrder, sortKey, windowSize, loading, datestring }) => {
  const [dropDownValue, setDropDownValue] = useState()
  const [isDropDownChanged, setIsDropDownChanged] = useState()
  const { pathname } = useLocation()
  const isSearchedByDate = pathname.includes('date')
  const eventTextChunk = `${isSearchedByDate ? '': '다음 '}기일`

  useEffect(() => {
    setDropDownValue(eventTextChunk)
  }, [isSearchedByDate])

  const renderNextEvent = (v, record) => {
    if (!v) {
      return ''
    }
    if (dropDownValue === '다음 기일') {
      const { datetime, type } = v
      const dt = parseDateTime(datetime)
      return (
        <NextEventContainer>
          <div>
            {type && <Tag color={_.get(eventColorcode, type)}>{type}</Tag>}
          </div>
          <Typography.Text className='multiline'>{dt}</Typography.Text>
          <Typography.Text className='multiline'>{v.location}</Typography.Text>
        </NextEventContainer>
      )
    } else if (dropDownValue === '기일') {
      const { datetime, type } = _.pick(_.find(v, ({ datetime }) => parseDate(datestring) === parseDate(datetime)), ['datetime', 'type'])
      const dt = parseDateTime(datetime)
      return (
        <NextEventContainer>
          <div>
            {type && <Tag color={_.get(eventColorcode, type)}>{type}</Tag>}
          </div>
          <Typography.Text className='multiline'>{dt}</Typography.Text>
          <Typography.Text className='multiline'>{v.location}</Typography.Text>
        </NextEventContainer>
      )
    } else {
      const date = parseDate(new Date(v))
      if (!date) return null
      return (
        <Typography.Text>{date}</Typography.Text>
      )
    }
  }
  const tableSortingItems = [eventTextChunk, '접수일']
  const columns = [
    {
      title: '사건',
      dataIndex: 'name',
      className: 'multiline',
      ellipsis: {
        showTitle: true
      },
      // fixed: 'left',
      render: (name, record) => <Typography.Link onClick={() => onNav(record.caseId)}>{name.trim()}</Typography.Link>
    },
    {
      title: () => (
        <TableTitleWithSort
          onUp={() => {
            setIsDropDownChanged(false)
            onSort('up', dropDownValue === '접수일' ? 'received' : 'nextCurrentEvent')
          }}
          onDown={() => {
            setIsDropDownChanged(false)
            onSort('down', dropDownValue === '접수일' ? 'received' : 'nextCurrentEvent')
          }}
          sortOrder={isDropDownChanged || (sortKey !== 'nextCurrentEvent' && sortKey !== 'received') ? null : sortOrder}
        >
          <DropDownSelect
            bordered={false}
            items={tableSortingItems}
            className='dropDown'
            value={dropDownValue}
            onSelect={val => {
              setDropDownValue(val)
              setIsDropDownChanged(true)
            }}
          />
        </TableTitleWithSort>
      ),
      // dataIndex: dropDownValue === '접수일' ? 'received' : 'nextEvent',
      dataIndex: dropDownValue === '접수일' ? 'received' : dropDownValue === '다음 기일' ? 'nextEvent' : 'nextEvents',
      ellipsis: true,
      render: renderNextEvent,
      // fixed: 'left'
    },
    {
      title: '당사자',
      dataIndex: 'party',
      render: items => (<PartyList>{_.map(items, (data, index) => <Party key={index} {...data} />)}</PartyList>)
    },
    {
      title: () => (
        <TableTitleWithSort
          title='관리명칭'
          onUp={() => onSort('up', 'managementName')}
          onDown={() => onSort('down', 'managementName')}
          sortOrder={sortKey === 'managementName' ? sortOrder : null}
        />),
      dataIndex: 'managementName'
    },
    {
      title: () => (
        <TableTitleWithSort
          title='상태'
          onUp={() => onSort('up', 'status')}
          onDown={() => onSort('down', 'status')}
          sortOrder={sortKey === 'status' ? sortOrder : null}
        />),
      dataIndex: 'status',
      ellipsis: true,
      width: 100,
      render: (status) => <Typography.Text>{_.get(textByStatus, [status], '오류')}</Typography.Text>
    }
  ]
  return (
    <Container>
      <Table
        size='small'
        dataSource={data}
        columns={columns}
        pagination={false}
        style={{ zIndex: 0 }}
        loading={loading}
        showSorterTooltip={false}
        scroll={{ x: windowSize === 'small' ? 'calc(500px + 50%)' : undefined }}
      />
    </Container>
  )
}

export default CaseTable
