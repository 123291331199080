import React from 'react'
import { Button, Result } from 'antd'

const NetworkError = () => (
  <Result
    status='warning'
    title='서버와의 연결에 실패하였습니다.'
    subTitle='인터넷 연결을 확인해보세요'
    extra={[
      <Button
        type='primary'
        key='console'
        onClick={() => window.location.replace('https://www.seoulbar.or.kr/api/sso_to_mycase.jsp')}
      >
        서울지방변호사회 홈페이지로 이동
      </Button>,
      <Button key='buy' onClick={() => window.location.reload()}>연결 재시도</Button>
    ]}
  />
)

export default NetworkError
