import _ from 'lodash'
import { Badge } from 'antd'

import { parseDate } from '../../config'

function CalendarTile({ date, view, markedDates }) {
  if (_.isEmpty(markedDates)) return null
  if (view !== 'month') {
    return null
  }
  const count = _.sumBy(_.filter(markedDates, ({ date: _date }) => parseDate(_date) === parseDate(date)), 'count')
  if (!count) {
    return null
  }

  return (
    <Badge count={count} size='small' />
  )
}

export default CalendarTile
