import styled from "styled-components"

const Container = styled.div.attrs(props => ({
  style: {
    flexDirection: props.flexDirection || 'column',
    alignItems: props.alignItems || 'flex-start'
  }
}))`
  display: flex;
  gap: 3px;
  flex-wrap: wrap;
`

const Label = ({ items, flexDirection = 'column', alignItems }) => {
  return (
    <Container flexDirection={flexDirection} alignItems={alignItems}>
      {items.map((label, index) => {
        return <div key={`label_${index}`}>{label}</div>
      })}
    </Container>
  )
}

export default Label
