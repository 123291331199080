import { Space, Typography } from 'antd'
import styled from 'styled-components'

const RowContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`

const TableTitle = ({ title, children, className }) => {
  return (
    <Space className={className}>
      <Typography.Title level={5} style={{ margin: 0 }}>{title}</Typography.Title>
      {children}
    </Space>
  )
}

export default TableTitle
