import _ from 'lodash'
import styled from 'styled-components'
import { Table } from 'antd'

import { caseDetailPaginationCode } from '../../../config'
import { CaseInfoTable, EventDateTable } from '../../Tables'
import { RadioSelect } from '../../common'
import TableTitle from '../../Tables/TableTitle'

const GAP = 20

const ResponsiveContainer = styled.div.attrs(props => ({
  style: {
    flexDirection: props.flexDirection || 'column'
  }
}))`
  display: flex;
  max-width: 1400px;
  flex-direction: ${props => props.flexDirection};
  gap: ${GAP}px;
  .gap-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${GAP}px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const EventTableContainer = styled.div.attrs(props => ({
  style: {
    flex: props.isLarge ? '1.5' : '1'
  }
}))`
  display: flex;
  flex-direction: column;
  z-index: 0;
  width: 100%;
`

const NavContainer = styled.div`
  position: fixed;
  background: white;
  top: 70px;
  left: 10px;
  width: calc(100% - 30px);
  z-index: 2;
  background-color: #fff;
`

const ResponsiveCaseDetailContent = (props) => {
  const {
    infoUpdated,
    events,
    status,
    initManagementData,
    windowSize,
    onEditFinish,
    extraInfoTables,
    idsForScrollPagination,
    scrollPositionOnMobile,
    onDeleteUserCase,
    onSetUserCase,
    onChangeScrollSetPosOnMobile,
    onSettingCaseNotification,
    ...eventTableProps
  } = props

  const elem1Id = _.get(idsForScrollPagination, [1])
  const elem2Id = _.get(idsForScrollPagination, [2])
  const elem3Id = _.get(idsForScrollPagination, [3])

  return (
    <ResponsiveContainer flexDirection={windowSize === 'large' ? 'row' : 'column'}>

      <Container className='gap-container' isLarge={windowSize === 'large'}>

        {windowSize === 'small' && (
          <NavContainer>
            <RadioSelect
              options={caseDetailPaginationCode}
              value={scrollPositionOnMobile}
              onChange={onChangeScrollSetPosOnMobile}
            />
          </NavContainer>)}
        <div id={elem1Id} style={{ display: 'none' }} />

        <div id={elem2Id} className='gap-container'>
          <CaseInfoTable
            size='small'
            status={status}
            data={infoUpdated}
            onSetUserCase={onSetUserCase}
            onDeleteUserCase={onDeleteUserCase}
            onEditFinish={onEditFinish}
            windowSize={windowSize}
            initManagementData={initManagementData}
          />
          {_.map(extraInfoTables, ({ key, columns, dataSource }) => {
            if (_.isEmpty(dataSource)) return null
            return (
              <Table
                key={key}
                size='small'
                style={{ maxWidth: 1400 }}
                title={() => <TableTitle title={key} />}
                pagination={false}
                scroll={{ x: true }}
                columns={columns}
                dataSource={dataSource}
              />
            )
          })}
        </div>
        
      </Container>
      
      <EventTableContainer isLarge={windowSize === 'large'}>
        <EventDateTable
          id={elem3Id}
          size='small'
          data={events}
          windowSize={windowSize}
          {...eventTableProps}
        />
      </EventTableContainer>

    </ResponsiveContainer>
  )
}

export default ResponsiveCaseDetailContent
