import ResponsiveCaseDetailContent from './CaseDetails'
import { useWindowSize } from '../../hook/useWindowSize'
import { CaseTable } from '../Tables'

const Responsive = ({ id, visible, onShowModal, ...props }) => {
  const { windowSize } = useWindowSize()

  if (id === 'caseDetail') {
    return (
      <ResponsiveCaseDetailContent windowSize={windowSize} {...props} />
    )
  } else if (id === 'caseTable') {
    return <CaseTable windowSize={windowSize} {...props} />
  }
}

export default Responsive
