import { Button, Modal, Typography } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styled from 'styled-components'

import { useWindowSize } from '../../hook/useWindowSize'

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ResponsiveModal = ({ children, ...props }, ref) => {
  const {
    style,
    width,
    title,
    renderButton,
    renderHeader,
    renderFooter,
    closable,
    defaultOpen = false,
    onOpenChange = () => {},
    onSave = () => {},
    onCancel = () => {}
  } = props
  const [open, setOpen] = useState(defaultOpen)
  const { windowSize } = useWindowSize()
  useEffect(() => {
    onOpenChange(open)
  }, [open])

  // handlers
  const handleCancel = () => {
    setOpen(false)
    onCancel()
  }
  const handleSave = () => {
    setOpen(false)
    onSave()
  }
  const handleOpen = () => setOpen(true)

  // variables
  const isForm = windowSize === 'small' && open

  // renderers
  const PassedButton = props => renderButton(props)
  const PassedHeader = props => renderHeader(props)
  const PassedFooter = props => renderFooter(props)

  useImperativeHandle(ref, () => ({
    close: () => setOpen(false),
    open: () => setOpen(true)
  }))

  return (
    <>
      {isForm
        ? (
          <FormContainer>
            {renderHeader
              ? <PassedHeader close={() => setOpen(false)} />
              : <FormHeader>
                  <Typography.Link onClick={handleCancel}>취소</Typography.Link>
                  <Typography.Link onClick={handleSave}>저장</Typography.Link>
                </FormHeader>}
            {children}
          </FormContainer>
        )
        : (
            <Modal
              title={title}
              open={open}
              width={width}
              destroyOnClose
              style={style}
              closable={closable}
              onCancel={handleCancel}
              footer={renderFooter ? <PassedFooter close={() => setOpen(false)} /> : null}
            >
              {children}
            </Modal>
          )}
      {isForm ? null : renderButton ? <><PassedButton onClick={handleOpen} /></> : <Button onClick={handleOpen}>버튼</Button>}
    </>
  )
}

export default forwardRef(ResponsiveModal)
