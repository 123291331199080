import { Spin, Typography } from 'antd'
import React from 'react'
import styled from 'styled-components'

import FormContent from './FormContent'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ErrorMessageContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  span + span {
    margin-left: 5px;
  }
`
const LoadingBlock = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fafafa80;

  display: flex;
  align-items: center;
  justify-content: center;
`

const AddCaseForm = (props) => {
  const {
    loading,
    failure,
    showPolicy,
    onChangeForm,
    caseInfo,
    errorMessage,
    isOpened
  } = props

  return (
    <Container>
      {failure && !showPolicy && (
        <ErrorMessageContainer>
          <Typography.Text type='danger'>{errorMessage || '법원명, 사건번호, 당사자명을 확인하세요.'}</Typography.Text>
        </ErrorMessageContainer>
      )}

      <FormContent
        loading={loading}
        failure={failure}
        caseInfo={caseInfo}
        isOpened={isOpened}
        onChangeForm={onChangeForm}
        showPolicy={showPolicy}
      />

      {loading && (
        <LoadingBlock>
          <Spin tip='사건을 찾는 중입니다...' />
        </LoadingBlock>
      )}
    </Container>
  )
}

export default AddCaseForm
