import * as queries from '../graphql/queries'
import * as mutations from '../graphql/mutations'
import * as subscription from '../graphql/subscription'
import { message } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import _ from 'lodash'
import { agreementVersion } from '../config'

const useAddCase = (skipSub, onSuccess = () => {}) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [failure, setFailure] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // gql
  const { subscribeToMore } = useQuery(queries.userCaseList, {
    errorPolicy: 'all',
    skip: skipSub
  })
  const { refetch: refetchCalendar } = useQuery(queries.calendarEvents)

  const onRegisterFail = (_message, shouldWarn) => {
    setLoading(false)
    setFailure(true)
    shouldWarn ? message.warning(_message) : message.error(_message)
    setErrorMessage(_message)
  } 
  const onRegisterSuccess = (caseId, _message) => {
    setLoading(false)
    onSuccess()
    refetchCalendar()
    if (_message === '이미 등록된 사건입니다.') {
      message.warning(_message)
    } else {
      message.success(_message)
    }
    console.log('register success', new Date())
    reset()
    navigate(`detail/${caseId}`)
  }
  const reset = () => {
    sessionStorage.setItem('prevData', JSON.stringify(null))
    sessionStorage.setItem('scrollTop', 0)
  }
  const onAddUserCaseComplete = (data) => {
    console.log('mutation finish', data)
    const { status, caseId } = _.pick(_.get(data, ['addUserCase', 'data']), ['status', 'caseId'])
    const messageToShow = _.get(data, ['addUserCase', 'message'])
    const { user } = client.readQuery({
      query: queries.user
    })
    client.writeQuery({
      query: queries.user,
      data: {
        user: {
          ...user,
          userAgreement: agreementVersion
        }
      }
    })
    if (status === 'PENDING' && caseId) {
      subscribeToMore({
        document: subscription.caseUpdated,
        variables: { caseId },
        updateQuery: (prev, { subscriptionData }) => {
          const subStatus = _.get(subscriptionData, ['data', 'caseUpdated', 'status'])
          if (subStatus === 'INVALID' || subStatus === 'PENDING' || subStatus === 'ERROR' || subStatus === 'NOT_READY') {       
            onRegisterFail(_.get(subscriptionData, ['data', 'caseUpdated', 'message']), subStatus === 'NOT_READY')
          } else onRegisterSuccess(caseId, _.get(subscriptionData, ['data', 'caseUpdated', 'message']))
          return prev
        },
        onError: (e) => {
          console.log('subscription failed', e)
          onRegisterFail()
        }
      })
    } else if (status === 'INVALID') {
      onRegisterFail(messageToShow)
    } else if (caseId) {
      onRegisterSuccess(caseId, messageToShow)
    } else {
      onRegisterFail(messageToShow)
    }
  }
  const [addUserCase, { client }] = useMutation(mutations.addUserCase, {
    onCompleted: onAddUserCaseComplete,
    onError: (error) => onRegisterFail()
  })

  // responsive form handlers
  const addCase = (caseInfo) => {
    const { caseNumber, partyName, court: { label: court } } = caseInfo
    const caseData = {
      caseNumber: _.trim(caseNumber),
      partyName: _.trim(partyName),
      court: _.trim(court),
    }
    setLoading(true)
    addUserCase({ variables: { data: caseData, userAgreement: agreementVersion } })
  }

  return { addCase, loading, errorMessage, failure }
}

export default useAddCase
