import { useEffect, useState } from "react"

const useScrollTop = () => {
  const [scrollTop, setScrollTop] = useState(sessionStorage.getItem('scrollTop') || 0)
  useEffect(() => {
    const scrollElem = document.getElementById('scrollContainer')
    if (scrollElem) {
      scrollElem.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (scrollElem) {
        scrollElem.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  const handleScroll =  e => {
    setScrollTop(e.target.scrollTop)
  }
  const updateScrollTop = top => {
    setScrollTop(top)
    const scrollElem = document.getElementById('scrollContainer')
    scrollElem.scrollTop = top
    sessionStorage.setItem('scrollTop', scrollTop)
  }
  return { scrollTop, updateScrollTop }
}

export default useScrollTop
