import { gql } from '@apollo/client'

export const _case = gql `
  query Case($caseId: Int) {
    case(caseId: $caseId) {
      caseId
      caseNumber
      court
      lastUpdated
      nextEvent {
        type
        result
        datetime
        location
      }
      status
      info {
        key
        value
      }
    }
  }
`;
export const userCaseList = gql `
  query UserCaseList(
    $filter: UserCaseListFilter
    $page: Int
    $casesPerPage: Int
    $sortMethod: SortMethod
  ) {
    userCaseList (
      filter: $filter
      page: $page
      casesPerPage: $casesPerPage
      sortMethod: $sortMethod
    ) {
      data {
        caseId
        name court
        managementName
        party { type name }
        caseNumber court lastUpdated
        partyName memo memoUpdated
        events { type date content result etc }
        info { key value }
        status
        nextEvent { datetime type }
        nextEvents { datetime type }
        received
      }
      total
    }
  }
`;
export const userCase = gql `
  query UserCase($caseId: Int) {
    userCase(caseId: $caseId) {
      name
      court
      managementName
      info { key value }
      status
      events { type date content result etc }
      memo
      appeals { court case_number }
    }
  }
`;
export const stat = gql `
  query Stat {
    stat {
      statId
    }
  }
`;
export const message = gql `
  query Message {
    message {
      messageId
    }
  }
`;
export const banner = gql `
  query Banner {
    banner {
      bannerId
    }
  }
`
export const calendarEvents = gql`
  query CalendarEvents {
    calendarEvents {
      date count
    }
  }
`
export const user = gql`
  query User {
    user {
      name
      userAgreement
      lastNoticeAccessed
      lastFaqAccessed
      lastNoticeUpdated
      lastFaqUpdated
    }
  }
`

export const noticeList = gql`
  query NoticeList {
    noticeList {
      data {
        noticeId
        number
        title
        created
        viewCount
      }
      total
  }
}`


export const notice = gql`
  query Notice ($id: Int) {
    notice (id: $id) {
      noticeId
      number
      title
      created
      viewCount
      content
    }
  }
`
export const banners = gql`
  query Banners {
    banners {
      bannerId
      created
      data
      externalLink
      type
    }
  }
`

export const faqList = gql`
  query Faq {
    faqList {
      faqId
      number
      title
    }
  }
`

export const faq = gql`
  query Faq($id: Int) {
    faq(id: $id) {
      faqId
      number
      title
      content
    }
  }
`