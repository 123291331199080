import { Table } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'

import { infoTableColumns } from './tableColumns'
import CaseControl from '../Responsive/CaseDetails/CaseControl'

const ColumnContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const CaseInfoTable = ({ data, size, id, status, extraInfoTables, mobile, initManagementData, ...caseControlProps }) => {
  const managementName = _.get(initManagementData, ['managementName'])
  const memo = _.get(initManagementData, ['memo'])
  let managementData = []
  if (managementName) {
    managementData = [
      {
        key: '관리명칭',
        value: managementName
      }
    ]
  }
  if (memo) {
    managementData = [
      ...managementData, 
      {
        key: '내용',
        value: <div dangerouslySetInnerHTML={ {__html: memo} } />
      }
    ]
  }
  return (
    <ColumnContainer id={id}>
      <Table
        size={size}
        style={{ maxWidth: 1400 }}
        columns={infoTableColumns}
        dataSource={managementData.concat(data)}
        pagination={false}
        showHeader={false}
        title={() => (
          <CaseControl
            status={status}
            initManagementData={initManagementData}
            {...caseControlProps}
          />
        )}
      />
    </ColumnContainer>
  )
}

export default CaseInfoTable
