import _ from 'lodash'
import { Modal } from 'antd'
import styled from 'styled-components'

import BannerHeader from './BannerHeader'
import BannerForm from './form'

import { useBanner } from '../../../hook/useBanner'
const Stub = styled.div`
  height: 40%;
`
const BannerResponsive = () => {
  const  {
    open,
    bannerList,
    closeBanner,
    closeBannerToday
  } = useBanner()

  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={closeBanner}
      title={<>
        <BannerHeader onCancel={closeBanner} onCancelToday={closeBannerToday} />
        <Stub />
      </> }
      width='80%'
      closable={false}
      style={{ maxWidth: 800 }}
      footer={() => null}
      renderButton={() => null}
    >
      <BannerForm bannerList={bannerList} />
    </Modal>
  )
}

export default BannerResponsive
