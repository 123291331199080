import _ from 'lodash'
import { Table } from 'antd'
import { useQuery } from '@apollo/client'

import HTMLContent from '../../../common/HTMLContent'
import { faq } from '../../../../graphql/queries'

const FaqItemForm = ({ id }) => {
  // quries
  const { data: faqData } = useQuery(faq, {
    variables: { id: id }, 
    skip: !id
  })


  // variables
  const faqItem = _.get(faqData, ['faq'], [])
  return (
    <HTMLContent data={_.get(faqItem, ['content'])} />
  )
}

export default FaqItemForm
