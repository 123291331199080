import { Table } from 'antd'
import _ from 'lodash'
import { useQuery } from '@apollo/client'

import { noticeList } from '../../../../graphql/queries'

const NotificationForm = ({ onClickRow, userQueryRefetch }) => {
  const { data: noticeListData } = useQuery(noticeList, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      userQueryRefetch()
    }
  })

  const handleRow = (record) => {
    return {
      onClick: () => {
        onClickRow(record.title, record.noticeId)
      }
    }
  }

  const notifications = _.map(_.get(noticeListData, ['noticeList', 'data'], []),
    ({ viewCount: views, ...items }) => {
      return { ...items, views }
    }
  )
  const notificationInfoColumns = [
    {
      title: '번호',
      dataIndex: 'number',
      width: 10
    },
    {
      title: '제목',
      dataIndex: 'title'
    },
    {
      title: '작성일',
      dataIndex: 'created'
    }
  ]

  return (
    <Table
      size='small'
      pagination={false}
      style={{ cursor: 'pointer' }}
      onRow={handleRow}
      dataSource={notifications}
      columns={notificationInfoColumns}
    />
  )
}

export default NotificationForm
