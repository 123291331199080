import _ from 'lodash'
import NotificationForm from './NotificationForm'
import FaqForm from './FaqForm'
import NotificationItemForm from './NotificationItemForm'
import FaqItemForm from './FaqItemForm'

const formByTitle = {
  '공지사항': {
    'list': NotificationForm,
    'item': NotificationItemForm
  },
  'FAQ': {
    'list': FaqForm,
    'item': FaqItemForm
  }
}
const SetUserForm = ({ onClickRow, path, ...props }) => _.get(formByTitle, [path[0], path.length === 2 ? 'item' : 'list'], () => {})({ path, onClickRow, ...props})
  
export default SetUserForm
