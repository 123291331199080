import _ from 'lodash'
import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { banners } from '../graphql/queries'

export const useBanner = () => {
  // hooks
  const [open, setOpen] = useState(null)

  const { data: bannersData, loading: bannerLoading } = useQuery(banners)
  const bannerList = _.get(bannersData, ['banners'], [])
  // handlers
  function closeBanner () {
    setOpen(false)
  }
  const closeBannerToday = () => {
    setOpen(false)
    var d = new Date()
    d.setDate(d.getDate() + 1)
    d.setHours(0, 0, 0, 0)
    localStorage.setItem('bannerDisabledUntil', d)
  }
  
  // variables
  const bannerDisabledUntil = localStorage.getItem('bannerDisabledUntil')
  const _bannerEnabled = bannerDisabledUntil ? new Date() > new Date(bannerDisabledUntil) : true
  const bannerEnabled = !_.isEmpty(bannerList) && _bannerEnabled

  useEffect(() => {
    if (bannerEnabled && !bannerLoading) setOpen(true)
  }, [bannerEnabled, bannerLoading])

  return {
    open,
    bannerList,
    closeBanner,
    closeBannerToday
  }
}
