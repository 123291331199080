import { Typography } from 'antd'
import _ from 'lodash'
import { useEffect } from 'react'
import styled from 'styled-components'

import ContentRow from './ContentRow'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  align-items: center;
  width: 100%;
`

const AgreementContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  min-height: 0px;
  flex-direction: column;
  line-height: 1.6;
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
    margin-top: 0 !important;
    margin-bottom: 0.2em !important;
  }
  div.ant-typography {
    margin-bottom: 0.5em;
    word-break: keep-all;
  }
`

const formRowItems = [
  {
    title: '법원',
    key: 'court',
    placeholder: '서울고등법원'
  },
  {
    title: '사건번호',
    key: 'caseNumber',
    placeholder: '2000가나123456'
  },
  {
    title: '당사자명',
    key: 'partyName',
    placeholder: '홍길동'
  }
]

const FormContent = ({ loading, failure, isOpened, onChangeForm, showPolicy, caseInfo }) => {
  const focusNext = (index) => {
    const elem = document.getElementById(`targetInput_${index + 1}`)
    setTimeout(() => {
      if (index === 2) {
        const saveButton = document.getElementById(`save-button`)
        saveButton?.focus()
      } else elem?.focus()
    }, 100)
  }
  if (showPolicy && !loading) {
    return (
      <AgreementContainer>
        <Typography.Title level={4}>제1조(목적)</Typography.Title>
        <Typography.Paragraph>본 운영정책은 서울지방변호사회 소속 변호사회원(이하 ‘회원’이라 한다.)에게 제공하는 사건관리 서비스(이하 ‘서비스’라 한다.)에 관한 사항을 규정함을 목적으로 합니다.</Typography.Paragraph>

        <Typography.Title level={4}>제2조(권한의 위임)</Typography.Title>
        <Typography.Paragraph className='indent'>① 회원은 자신이 서비스에 입력한 소송 등 사건(이하 ‘회원의 사건’이라 한다.)에 관하여 대법원에서 제공하는 ‘나의 사건검색’ 서비스를 통해 그 사건의 사건일반내용, 사건진행내용, 송달결과확인 등 모든 제반 사항 등 정보를 수시로 확인 및 처리할 수 있는 모든 권한을 서울지방변호사회(이하 ‘서울회’라 한다.)에 위임합니다.</Typography.Paragraph>
        <Typography.Paragraph className='indent'>② 서울회가 제1항의 위임에 따라 회원의 사건을 서비스 상에 등록을 완료한 경우 서울회는 서비스 제공 및 서울회 운영상의 목적을 위해 회원의 사건 관련 정보를 확인 또는 처리할 수 있습니다. 이 때, 회원이 회원의 사건 등록을 취소 또는 삭제하거나, 서비스 해지 등을 통해 서비스 이용을 중단하더라도 그것은 제1항의 위임을 해지하는 효과를 발생시키지 않으며, 서울회는 제1항의 위임에 따른 권한을 행사할 수 있습니다.</Typography.Paragraph>

        <Typography.Title level={4}>제3조(정보의 이용)</Typography.Title>
        <Typography.Paragraph className='indent'>① 서울회는 위 제2조를 통해 회원의 사건에 관하여 얻은 정보를 서버에 저장하고, 이를 가공하여 회원에게 제공할 수 있습니다.</Typography.Paragraph>
        <Typography.Paragraph className='indent'>② 서울회는 위 제2조를 통해 회원의 사건에 관하여 얻은 정보를 익명화 후 취합, 분석, 가공하여 개별 사건의 내용을 다양한 형태의 통계자료 등의 방식으로 회원에게 제공할 수 있으며, 회원은 이에 이의를 제기하지 않습니다.</Typography.Paragraph>


        <Typography.Title level={4}>제4조(면책)</Typography.Title>
        <Typography.Paragraph className='indent'>① 서비스 제공과 관련하여 서울회에 손해가 발생하거나 제3자로부터 권리 침해의 청구가 있는 경우, 위법행위를 행하는 등 귀책사유 있는 회원은 서울회에 발생되는 모든 손해를 배상하여야 하며 동 손해로부터 서울회를 면책시켜야 합니다.</Typography.Paragraph>
        <Typography.Paragraph className='indent'>② 서울회는 회원의 선택에 따라 회원의 사건의 진행정보를 주기적으로 확인하여 업데이트 정보, 기일정보 등을 회원에게 휴대폰 알림 등의 방식으로 통지할 수 있습니다. 단, 회원의 사건에 대한 실제 진행정보, 기일정보 등을 확인할 의무는 회원에게 있으며, 회원이 이를 확인하지 않아 발생한 손해에 대하여 일체의 책임을 지지 않습니다.</Typography.Paragraph>
        <Typography.Paragraph className='indent'>③ 서울회는 대법원 ‘나의사건검색’ 서비스에서 잘못된 정보를 제공하거나 홈페이지(www.seoulbar.or.kr) 서비스 이용약관 제19조(책임제한)에 정해진 사유 기타 이에 상응하는 사유로 서비스를 제공하지 못함으로 인하여 발생한 손해에 일체의 책임을 지지 않습니다.</Typography.Paragraph>

        <Typography.Title level={4}>제5조(유료서비스)</Typography.Title>
        <Typography.Paragraph className='indent'>① 서울회는 무료 서비스를 전제로 제공하지만 운영상황에 따라 전부 또는 일부를 유료서비스로 전환할 수 있습니다.</Typography.Paragraph>
        <Typography.Paragraph className='indent'>② 서울회는 유료서비스로 전환 시 유료회원이 아닌 회원에게 서비스의 이용을 제한할 수 있습니다.</Typography.Paragraph>

        <Typography.Title level={4}>제6조(운영방식)</Typography.Title>
        <Typography.Paragraph>서울회는 회원 고지 후 기존 서비스의 변경/폐지, 신규 서비스 출시 서비스의 유료화 등을 할 수 있습니다.</Typography.Paragraph>
      </AgreementContainer>
    )
  }

  return (
    <Container>
      {formRowItems.map(({ key, title, placeholder }, index) => {
        return (
          <ContentRow
            id={`targetInput_${index}`}
            key={key}
            title={title}
            failure={failure}
            isOpened={isOpened}
            placeholder={placeholder}
            value={_.get(caseInfo, [key], '')}
            focusNext={() => focusNext(index)}
            onChangeInput={value => onChangeForm(value, key)}
          />
        )
      })}
    </Container>
  )
}

export default FormContent
