import { useState } from 'react'
import styled from 'styled-components'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

import Logo from '../Logo'
import ErrorReport from './ErrorReport'
import AddCaseResponsive from './AddCaseResponsive'
import SetUserResponsive from './SetUserResponsive'

const { Search } = Input

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px 10px;
`
const FlexStub = styled.div`
  display: flex;
  flex: 0.4;
`
const RowContainer = styled.div.attrs(props => ({
  style: props.open ? {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  } : {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  }
}))`
  gap: ${props => props.size === 'large' ? '30px' :  props.size === 'middle' ? '15px' : '10px'};
`

const Header = (props) => {
  const {
    windowSize,
    userName,
    didUserAgree,
    isUnreadByTitle,
    userQueryRefetch,
    onOpenChange,
    onAddErrorReport
  } = props

  const navigate = useNavigate()
  const [opened, setOpened] = useState(null)
  const [isFocused, setIsFocused] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)

  // functions
  const handleSearch = (e) => {
    if (!searchQuery) return
    if (!searchQuery.trim()) return // for preventing searching ' '
    if (!e || !e.trim()) return
    navigate(`/number/${searchQuery}`)
  }
  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)
  const handleResponsiveOpenChange = (_opened) => {
    setOpened(_opened)
    onOpenChange(_opened)
  }

  // variables
  const isMobile = windowSize === 'small'
  const isSearchingInMobileWindow = isMobile && isFocused
  const hidden = opened && isMobile // when showing full screen mobile form hide other components

  if (isSearchingInMobileWindow) {
    return (
      <Container>
        <Search
          allowClear
          value={searchQuery}
          autoFocus={isFocused}
          style={{ flex: 1, width: '100%' }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onSearch={handleSearch}
          onChange={({ target: { value: newValue } }) => setSearchQuery(newValue)}
        />
      </Container>
    )
  }
  return (
    <Container>

      {hidden ? null : <Logo onClickTitle={() => navigate('/')} size={windowSize} />}
        
      {hidden ? null : <FlexStub />}

      <RowContainer size={windowSize} open={hidden}>
        {hidden ? null : <ErrorReport onAddErrorReport={onAddErrorReport} isMobile={isMobile} />}

        {hidden ? 
          null: 
          isMobile
            ? <SearchOutlined onClick={() => setIsFocused(true)} />
            :(
              <Search
                allowClear
                value={searchQuery}
                autoFocus={isFocused}
                placeholder='사건번호 검색'
                style={{ flex: 1 }}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onSearch={handleSearch}
                onChange={({ target: { value: newValue } }) => setSearchQuery(newValue)}
              />)}

        {!isFocused && (
          <AddCaseResponsive
            windowSize={windowSize}
            isButtonHidden={hidden}
            didUserAgree={didUserAgree}
            onOpenChange={(e) => handleResponsiveOpenChange(e, 'addCase')}
          />
        )}

        {!isFocused && (
          <SetUserResponsive
            userName={userName}
            windowSize={windowSize}
            isButtonHidden={hidden}
            isUnreadByTitle={isUnreadByTitle}
            userQueryRefetch={userQueryRefetch}
            onOpenChange={(e) => handleResponsiveOpenChange(e, 'setUser')}
          />
        )}
      </RowContainer>

    </Container>
  )
}

export default Header
