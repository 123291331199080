import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons'

const CustomSorter = ({ onUp = () => {}, onDown = () => {}, sortOrder }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <CaretUpFilled
        onClick={onUp}
        className={`anticon anticon-caret-up ant-table-column-sorter-up${sortOrder === 'up' ? ' active' : ''}`}
      />
      <CaretDownFilled
        onClick={onDown}
        className={`anticon anticon-caret-down ant-table-column-sorter-down${sortOrder === 'down' ? ' active' : ''}`}
      />
    </div>
  )
}

export default CustomSorter
