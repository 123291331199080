import _ from 'lodash'
import { useQuery } from '@apollo/client'

import HTMLContent from '../../../common/HTMLContent'
import { notice } from '../../../../graphql/queries'

const NotificationItemForm = ({ id }) => {
  const { data: noticeData } = useQuery(notice, {
    variables: { id: id },
    fetchPolicy: 'network-only'
  })
  const notification = _.get(noticeData, ['notice'])
  return (
    <HTMLContent data={_.get(notification, ['content'])} />
  )
}

export default NotificationItemForm
